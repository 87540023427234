<template>
  <Popup popupType="tableType" width="984px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">업체 선택</h3>
      </div>
      <div class="body_section">
        <ContractPartnerPopupFilterBar
          :autoDataList="autoDataList"
          @onClickSearch="onClickSearch"
          @onAutoComplete="onAutoComplete"
        />
        <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 453px)"
          scrollMaxHeight="343px"
          scrollMinHeight="49px"
          :showPagination="false"
        >
          <template v-slot:colgroup>
            <col style="width: 128px" />
            <!-- 업체명 -->
            <col style="width: 108px" />
            <!-- 파트너사 담당자 -->
            <col style="width: 104px" />
            <!-- 담당 업무 -->
            <col style="width: 190px" />
            <!-- 취급 품목 -->
            <col style="width: 192px" />
            <!-- 이메일 -->
            <col style="width: 109px" />
            <!-- 휴대전화 -->
            <col />
            <!-- 사업자 번호 -->
          </template>
          <template v-slot:tr>
            <th style="width: 128px">업체명</th>
            <th style="width: 108px">파트너사 담당자</th>
            <th style="width: 104px">담당 업무</th>
            <th style="width: 190px">취급 품목</th>
            <th style="width: 192px">이메일</th>
            <th style="width: 109px">휴대전화</th>
            <th style="width: 137px">사업자 번호</th>
          </template>
          <template v-slot:rows>
            <template v-for="item in dataList">
              <ContractPartnerPopupLine
                :key="item.personNum"
                :rowData="item"
                :isSelected="rowSelected && rowSelected.personNum === item.personNum"
                @onClickLine="onClickLine(item)"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="7" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button type="submit" class="btn_secondary btn_medium" @click="onClickAdd">추가</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import ContractPartnerPopupFilterBar from "./ContractPartnerPopupFilterBar";
import ContractPartnerPopupLine from "./ContractPartnerPopupLine";
import Board from "@/components/common/board/Board";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import ApiService from "@/services/ApiService";

export default {
  components: {
    Popup,
    ContractPartnerPopupFilterBar,
    ContractPartnerPopupLine,
    Board,
    CheckboxItem,
  },
  data() {
    return {
      dataList: [],
      autoDataList: [],
      rowSelected: null,
    };
  },
  created() {
    const path = `${this.$apiPath.PARTNER_PERSON_LIST}`;
    this.getData(path);
  },
  methods: {
    onClickLine(item) {
      this.rowSelected = item;
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickAdd() {
      if (!this.rowSelected) {
        return;
      }

      this.$emit("onClickAdd", this.rowSelected);
    },
    onClickSearch(objData) {
      const { radioSelectedId, inputCurrentText } = objData;
      const strParams = this.getParams(radioSelectedId, inputCurrentText);
      const path = `${this.$apiPath.PARTNER_PERSON_LIST}?${strParams}`;

      this.getData(path);
    },
    getParams(radioSelectedId, inputCurrentText) {
      // switch( radioSelectedId ) {
      //   case 'companyName': return `companyName=${ inputCurrentText }`;
      //   case 'name': return `name=${ inputCurrentText }`;
      //   case 'job': return `job=${ inputCurrentText }`;
      //   case 'corporateNum': return `corporateNum=${ inputCurrentText }`;
      // }
      // return null;

      if (!radioSelectedId) return null;

      return `${radioSelectedId}=${inputCurrentText}`;
    },
    async getData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.dataList = [];
        return;
      }

      this.dataList = result.data;
    },
    onAutoComplete(objData) {
      const { radioSelectedId, inputCurrentText } = objData;

      const strParams = this.getParams(radioSelectedId, inputCurrentText);
      const path = `${this.$apiPath.PARTNER_PERSON_SEARCH_LIST}?${strParams}`;

      this.getAutoData(path);
    },
    async getAutoData(path) {
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.autoDataList = [];
        return;
      }

      this.autoDataList = result.data;
    },
  },
};
</script>
