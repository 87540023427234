import { getMoneyToNumber } from "@/utils/stringUtils";

export default class SettleUnitCostModel {
  constructor() {
    // this.objRaw = null;

    this.contractItemList = [];

    this.createItem();
  }

  createItem() {
    const newItem = { unitPrice: "0" };

    this.contractItemList.push(newItem);
  }

  setData(objRaw) {
    // this.objRaw = objRaw;

    const { contractItemList } = objRaw;

    if (contractItemList) this.contractItemList = contractItemList;
  }

  getData() {
    // const obj = Object.assign( {}, this.objRaw );
    let obj = {};

    if (this.contractItemList) {
      obj.contractItemList = this.contractItemList.map((item) => {
        const row = {
          unitPrice: getMoneyToNumber(item.unitPrice),
        };

        return row;
      });
    }

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}
