<!-- 구매요청문서에서 전자결제를 승인 받은거 -->
<template>
  <Popup popupType="tableType" width="904px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">견적서 선택</h3>
      </div>
      <div class="body_section">
        <EstimateRequestsPopupFilterBar @onClickSearch="onClickSearch" />
        <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 497px)"
          scrollMaxHeight="442px"
          scrollMinHeight="49px"
          :showPagination="true"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 선택 -->
            <col style="width: 52px" />
            <!-- 요청일 -->
            <col style="width: 88px" />
            <!-- 견적요청번호 -->
            <col style="width: 135px" />
            <!-- 견적요청서명 -->
            <col style="width: 308px" />
            <!-- 파트너사 -->
            <col style="width: 138px" />
            <!-- 파트너사 담당자 -->
            <col style="width: 138px" />
          </template>
          <template v-slot:tr>
            <th style="width: 52px" />
            <th style="width: 88px">요청일</th>
            <th style="width: 135px">견적번호</th>
            <th style="width: 308px">견적서명</th>
            <th style="width: 138px">파트너 사</th>
            <th style="width: 138px">파트너사 담당자</th>
          </template>
          <template v-slot:rows>
            <template v-for="item in dataList">
              <EstimateRequestsPopupLine
                :key="item.estimateNum"
                :isMultiMode="isMultiMode"
                :type="type"
                :selectedItem.sync="selectedItem"
                :selectedDataList.sync="selectedDataList"
                :rowData="item"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="5" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button
        type="button"
        class="btn_secondary btn_medium"
        :disabled="!selectedItem.estimateNum && selectedDataList.length < 1"
        @click="onClickSelect"
      >
        선택
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import EstimateRequestsPopupFilterBar from "./EstimateRequestsPopupFilterBar";
import Board from "@/components/common/board/Board";
import EstimateRequestsPopupLine from "./EstimateRequestsPopupLine";

import { dateToString } from "@/utils/dateUtils";
import { board } from "@/constants/options";
import EstimateRequestsPopupMixin from "./EstimateRequestsPopupMixin";

export default {
  name: "EstimateRequestsPopup",
  components: {
    Popup,
    EstimateRequestsPopupFilterBar,
    EstimateRequestsPopupLine,
    Board,
  },
  mixins: [EstimateRequestsPopupMixin],
  data() {
    return {
      API_PATH: `${this.$apiPath.QUOTATION}/selection`,
      size: 10,
      totalPageCount: 0,
      currentPageIndex: 0,
    };
  },
  computed: {
    maxPaginationCount() {
      return board.MAX_PAGINATION_COUNT;
    },
  },
  created() {
    const params = this.checkUrlQuery();
    this.getDataInit(params);
  },
  methods: {
    getFilterbarParams(objData) {
      const { dates, radioSelectedId, inputCurrentText } = objData;
      let params = {};
      //params.status = this.status;
      if (radioSelectedId && inputCurrentText) {
        params[radioSelectedId] = inputCurrentText;
      }
      if (dates.length === 2) {
        const startDate = dateToString(dates[0]);
        const endDate = dateToString(dates[1]);
        params.regDate1 = startDate;
        params.regDate2 = endDate;
      }
      return params;
    },
  },
};
</script>
