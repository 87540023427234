import { stringToDate, dateToString } from "@/utils/dateUtils";
import { getMoneyToNumber, getMoneyToKorean } from "@/utils/stringUtils";

class ContractInfoModel {
  constructor() {
    // this.objRaw = null;
    this.projectCode = "";
    this.projectName = "";

    this.dates = [];
    this.description = ""; // 계약명.
    this.negoMemo = ""; // 업체 평가 확인.

    this.requestTotalPrice = null; //구매요청서에서 가져오는 토탈금액
    this.firstPrice = "";
    this.finalPrice = ""; // 최종계약금액
    this.finalPriceKr = ""; // 최종계약금액 한글표기
    this.vat = ""; // 부가세 여부
    // this.marketPrice = '';

    this.contractDiv = ""; // 계약 구분. O: OPEX, C: CAPEX
    this.negoMain = "P"; // 협상 파트. P: 구매파트, B: 현업
    this.contractStatus = "P"; // 계약 상태.
    this.reduceStandard = null; // 절감 기준. C: 기존계약금액, E: 초도견적, B: 예산

    this.paymentDate = "1"; // 대금지급기한
    this.payDay = ""; //월정산주기

    this.postCid = "";
    this.orderCid = "";

    this.category0 = null;
    this.category1 = null;
    this.category2 = null;

    this.currencyCode = "KRW";

    this.contractId = "";
    this.contractDept = "";

    this.cutOffPrice = ""; // 절사 금액?

    this.contractDept = ""; // 요청 부서.

    this.companyCode = "";
    this.commonReqName = ""; // 계약 요청자 인풋.

    this.postTitle = "";
  }

  setContractDept(str) {
    this.contractDept = str;
  }

  setData(objRaw) {
    // this.objRaw = objRaw;

    const {
      description,
      projectCode,
      projectName,
      negoMemo,
      requestTotalPrice,
      firstPrice,
      finalPrice,
      priceKr,
      vat,
      marketPrice,
      contractStatus,
      contractDiv,
      negoMain,
      reduceStandard,
      paymentDate,
      payDay,
      postCid,
      orderCid,
      currencyCode,
      cateNum,
      contractStartDate,
      contractEndDate,
      contractId,
      contractDept,
      cutOffPrice,
      cate1,
      cate2,
      cate3,
      companyCode,
      commonReqName,
    } = objRaw;

    this.description = description;
    this.projectCode = projectCode;
    this.projectName = projectName;

    if (negoMemo) this.negoMemo = negoMemo;
    if (requestTotalPrice) this.requestTotalPrice = requestTotalPrice;
    if (firstPrice || firstPrice === 0) this.firstPrice = firstPrice;
    if (finalPrice) this.finalPrice = finalPrice;
    if (finalPrice) this.finalPriceKr = getMoneyToKorean(finalPrice) + "원";
    if (vat) this.vat = vat;
    // if( marketPrice )  this.marketPrice = marketPrice;

    if (contractStatus) this.contractStatus = contractStatus;
    if (contractDiv) this.contractDiv = contractDiv;
    if (negoMain) this.negoMain = negoMain;
    if (reduceStandard) this.reduceStandard = reduceStandard;

    if (paymentDate) this.paymentDate = paymentDate;
    if (payDay) this.payDay = payDay;

    if (postCid) this.postCid = postCid;
    if (orderCid) this.orderCid = orderCid;
    if (currencyCode) this.currencyCode = currencyCode;
    if (cateNum) this.cateNum = cateNum;

    if ((contractStartDate, contractEndDate)) {
      this.dates = [stringToDate(contractStartDate), stringToDate(contractEndDate)];
    }

    if (contractId) this.contractId = contractId;
    if (contractDept) this.contractDept = contractDept;
    if (cutOffPrice) this.cutOffPrice = cutOffPrice;

    if (cate1) this.category0 = cate1;
    if (cate2) this.category1 = cate2;
    if (cate3) this.category2 = cate3;

    if (contractDept) this.contractDept = contractDept;

    if (companyCode) this.companyCode = companyCode;
    if (commonReqName) this.commonReqName = commonReqName;
  }

  setDataFromAnnounceSelect(objRaw) {
    const { postCid, orderCid, title } = objRaw;

    if (postCid) this.postCid = postCid;
    if (orderCid) this.orderCid = orderCid;
    if (title) this.postTitle = title;
  }

  getData() {
    // let obj = Object.assign( {}, this.objRaw );
    let obj = {};

    obj.description = this.description;
    obj.projectCode = this.projectCode;
    obj.projectName = this.projectName;

    if (this.negoMemo) obj.negoMemo = this.negoMemo;

    if (this.firstPrice) {
      obj.firstPrice = getMoneyToNumber(this.firstPrice);
    } else {
      obj.firstPrice = 0;
    }

    if (this.finalPrice) obj.finalPrice = getMoneyToNumber(this.finalPrice);
    if (this.marketPrice) obj.marketPrice = this.marketPrice;
    if (this.finalPrice) obj.priceKr = this.priceKr;
    if (this.vat) obj.vat = this.vat;

    if (this.contractStatus) {
      obj.contractStatus = this.getNextStatus(this.contractStatus);
    }

    if (this.contractDiv) obj.contractDiv = this.contractDiv;
    if (this.reduceStandard) obj.reduceStandard = this.reduceStandard;

    if (this.negoMain) obj.negoMain = this.negoMain;

    //if( this.paymentDate )  obj.paymentDate = this.paymentDate;
    //if( this.payDay ) obj.payDay = this.payDay;

    if (this.postCid) obj.postCid = this.postCid;
    if (this.orderCid) obj.orderCid = this.orderCid;

    if (this.currencyCode) obj.currencyCode = this.currencyCode;

    const cate = this.category2 || this.category1 || this.category0;
    if (cate) {
      obj.cateNum = cate;
    }

    if (this.dates && this.dates.length === 2) {
      obj.contractStartDate = dateToString(this.dates[0]);
      obj.contractEndDate = dateToString(this.dates[1]);
    }

    if (this.contractId) obj.contractId = this.contractId;
    if (this.contractDept) obj.contractDept = this.contractDept;
    if (this.cutOffPrice) obj.cutOffPrice = this.cutOffPrice;

    if (this.contractDept) obj.contractDept = this.contractDept;

    if (this.companyCode) obj.companyCode = this.companyCode;
    obj.commonReqName = this.commonReqName;

    return obj;
  }

  getAlertMessage() {
    if (!this.dates || this.dates.length !== 2) return "계약 기간을 확인해주세요.";
    if (!this.description) return "계약명을 확인해주세요.";
    //if (!this.category0) return '계약 정보 분류를 확인해주세요.';
    // if( !this.firstPrice ) return '절감 기준 금액을 확인해주세요.';

    // if( !this.reduceStandard ) return '절감 기준을 확인해주세요.';

    return null;
  }

  getNextStatus(status) {
    return "E";
  }
}

export default ContractInfoModel;
