<template>
  <tr :class="{ tr_select: isSelected }" @click="onClickLine">
    <td class="td_ellip" :data-ellip="rowData.companyName">
      {{ rowData.companyName }}
    </td>
    <td>{{ rowData.name }}</td>
    <td class="td_ellip" :data-ellip="rowData.job">
      {{ rowData.job }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.categoryItem">
      {{ rowData.categoryItem }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.email">
      {{ rowData.email }}
    </td>
    <td>{{ rowData.mobile }}</td>
    <td>{{ rowData.corporateNum }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    rowData: Object,
    isSelected: Boolean,
  },
  methods: {
    onClickLine() {
      this.$emit("onClickLine", this.rowData);
    },
  },
};
</script>
