<template>
  <Popup>
    <div v-scroll-lock="isModalMode" class="purchase_layer">
      <div class="inner_comm_layer inner_print_layer">
        <!-- 글로싸인 미리보기 영역 -->
        <div class="area_iframe">
          <div id="iframe" />
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import Sticky from "@/components/layout/content/Sticky.vue";

export default {
  name: "TemplateEtcPopup",
  components: {
    Popup,
    Sticky,
  },
  props: {
    receiver: Array,
    clientId: String,
  },
  data() {
    return {
      isModalMode: true,
    };
  },
  mounted() {
    let options = {
      container: "iframe",
      viewerType: "contract",
      signOrder: "false",

      receiver: this.receiver,
      clientId: this.clientId,
    };
    let viewer = new glosapi.viewer("y16X4tFyJNvScb2JS8KXchAm6").setOptions(options);

    viewer.open();

    viewer.setCallback((result) => {
      this.$emit("onClickClose", result.result);
    });
  },
  beforeDestroy() {
    this.isModalMode = false;
  },
};
</script>

<style scoped>
.inner_comm_layer .inner_print_layer {
  width: 1350px;
}
</style>
