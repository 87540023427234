<template>
  <tr :class="{ tr_select: isSelected }" @click="onClickSelect">
    <td>{{ rowData.templateType }}</td>
    <td>{{ rowData.templateGubun }}</td>
    <!-- <td>{{ rowData.templateId }}</td> -->
    <td class="td_ellip align_left" :data-ellip="rowData.templateName">
      {{ rowData.templateName }}
    </td>
    <td>{{ rowData.regDate.substr(0, 10) | dateStringFormat }}</td>
  </tr>
</template>

<script>
//import ListLineMixin from '@/mixins/ListLineMixin'

export default {
  name: "TemplateSelectPopupLine",
  props: {
    rowData: Object,
    isSelected: Boolean,
  },
  // mixins: [
  //   ListLineMixin
  // ],
  methods: {
    onClickSelect() {
      this.$emit("onClickSelectItem", this.rowData);
    },
  },
};
</script>
