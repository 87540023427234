<template>
  <Popup popupType="tableType" width="864px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section group_form">템플릿 선택</h3>
      </div>
      <div class="body_section">
        <!--<TemplateSelectPopupFilterBar
          :typeData01="typeData01"
          :typeData02="typeData02"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
          @onClickTemplateAdd="onClickTemplateAdd" />-->
        <TemplateSelectPopupFilterBar
          :typeData02="statusList"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          :isSelectBoxDisabled="isSelectBoxDisabled"
          @onClickSearch="onClickSearch"
          @onClickTemplateAdd="onClickTemplateAdd"
        />
        <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 453px)"
          scrollMaxHeight="343px"
          scrollMinHeight="49px"
          :showPagination="false"
        >
          <template v-slot:colgroup>
            <!-- 160 -->
            <col style="width: 117px" />
            <!-- 템플릿 용도 -->
            <col style="width: 180px" />
            <!-- 구분 -->
            <col style="width: 391px" />
            <!-- 템플릿 명 -->
            <col />
            <!-- 등록일 -->
          </template>
          <template v-slot:tr>
            <th style="width: 117px">템플릿 용도</th>
            <th style="width: 180px">구분</th>
            <th style="width: 391px">템플릿 명</th>
            <th style="width: 152px">등록일</th>
          </template>
          <template v-slot:rows>
            <template v-for="(item, index) in dataList">
              <TemplateSelectPopupLine
                :key="index"
                :isSelected="getIsSelected(item)"
                :rowData="item"
                @onClickSelectItem="onClickSelectItem(item)"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="4">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <!-- <button type="button" class="btn_tertiary btn_medium">템플릿 등록</button> -->
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button type="submit" class="btn_secondary btn_medium" @click="onClickSubmit">선택</button>
    </div>
  </Popup>
</template>

<script>
import ListPageMixin from "@/mixins/ListPageMixin";

import { mapState } from "vuex";

import Popup from "@/components/layout/popup/Popup";
import TemplateSelectPopupFilterBar from "./TemplateSelectPopupFilterBar";
import Board from "@/components/common/board/Board";
import TemplateSelectPopupLine from "./TemplateSelectPopupLine";

import ApiService from "@/services/ApiService";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { GET_TEMPLATE_GUBUN_LIST } from "@/store/modules/template/action";

export default {
  name: "TemplateSelectPopup",
  components: {
    Popup,
    TemplateSelectPopupFilterBar,
    Board,
    TemplateSelectPopupLine,
  },
  mixins: [ListPageMixin],
  props: {
    beforePage: String,
    templateGubun: {
      type: String,
      default: "",
    },
    isSelectBoxDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      API_PATH: this.$apiPath.TEMPLATE_LIST,
      typeData01: {
        dataList: [
          { code: "0", desc: "전체" },
          { code: "type01", desc: "계약서" },
          { code: "type02", desc: "발주서" },
          { code: "type03", desc: "협약서" },
        ],
        selectedId: "0",
      },
      //typeData02: {},
      // typeData02:{
      //   dataList:[
      //     {code:'0',  desc:'전체'},
      //     {code:'C',  desc:'기본 템플릿'},
      //     {code:'S',  desc:'별도 템플릿'},
      //   ],
      //   selectedId:'0'
      // },
      dataList: [],
      selectedItem: "",
      //isSelected: false,
      defaultStateId: "",
    };
  },
  beforeMount() {
    this.$store.dispatch(GET_TEMPLATE_GUBUN_LIST).then(() => {
      this.getDataInitial();
      if (this.templateGubun) {
        this.defaultStateId = this.templateGubun;
      }
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.template.statusList,
    }),
  },
  methods: {
    async getDataInitial() {
      const path = `${this.$apiPath.TEMPLATE_LIST}?&page=1&size=500&templateGubun=${this.templateGubun}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) return;

      this.dataList = result.data;
    },
    getIsSelected(item) {
      if (!this.selectedItem) return false;
      return this.selectedItem.templateId === item.templateId;
    },
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objGubun = {};

      if (query.templateGubun) {
        objGubun.templateGubun = query.templateGubun;
      }

      const objText = {};

      if (query.templateName) {
        objText.templateName = query.templateName;
      }

      return Object.assign({}, objPaging, objGubun, objText);
    },
    onClickTemplateAdd() {
      const path = `${this.$routerPath.TEMPLATE_WRITE}`;

      var win = window.open(path, "_blank", "opener");
      var timer = setInterval(
        function () {
          if (win.closed) {
            this.refresh();
            clearInterval(timer);
          }
        }.bind(this),
        1000,
      );
    },
    onClickSelectItem(item) {
      this.selectedItem = item;
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSubmit() {
      if (!this.selectedItem) {
        this.alert("사용하실 탬플릿을 선택해주세요.");
        return;
      }

      if (this.beforePage) {
        if (this.selectedItem.templateType.indexOf(this.beforePage) == -1) {
          const msg = this.beforePage + "서에 맞는 템플릿을 선택해주세요";
          this.alert(msg);

          return;
        }
      }

      const params = {
        text: "선택하신 탬플릿을 사용하시겠습니까? ",
        onClickY: () => this.$emit("onClickSubmit", this.selectedItem),
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, templateGubun } = objData;
      let params = {};

      // if( keywordSelectedId == 'title' && keywordInputText != undefined ) {params.templateName = keywordInputText;}
      // if( keywordSelectedId == 'id' && keywordInputText != undefined) {params.templateGubun = templateGubun;}

      if (keywordInputText) {
        params.templateName = keywordInputText;
      }
      if (templateGubun) {
        params.templateGubun = templateGubun;
      }

      return params;
    },
    async getData(params) {
      // 결과값이 {} 로 내려와서 {code,data:[templateList: ] .. }  오버라이드.
      if (this.ADD_PARAMS) {
        params = Object.assign(params, this.ADD_PARAMS);
      }

      const urlRest = makeQueryStringByObject(params);
      const url = this.API_PATH + urlRest;

      const result = await ApiService.shared.getData(url);

      const { data, total } = result;

      if (data) {
        //this.dataList = result.data.templateList;
        this.dataList = result.data;
        this.totalPageCount = parseInt(total);
      } else {
        this.dataList = [];
        this.totalPageCount = 0;
      }
    },
    refresh() {
      this.getDataInitial();
    },
  },
};
</script>
