class PaymentInfoModel {
  constructor() {
    this.payMethod = ""; // 대금 지급 방법
    this.payCondition = ""; // 대금 지급 조건
    this.goodsPlace = ""; // 납품 장소
    this.deposit = ""; // 계약/하자 보증금
    this.otherTerms = ""; // 기타
  }

  setData(objRaw) {
    if (objRaw.payMethod) this.payMethod = objRaw.payMethod;
    if (objRaw.payCondition) this.payCondition = objRaw.payCondition;
    if (objRaw.goodsPlace) this.goodsPlace = objRaw.goodsPlace;
    if (objRaw.deposit) this.deposit = objRaw.deposit;
    if (objRaw.otherTerms) this.otherTerms = objRaw.otherTerms;
  }

  getData() {
    const obj = {
      payMethod: this.payMethod,
      payCondition: this.payCondition,
      goodsPlace: this.goodsPlace,
      deposit: this.deposit,
      otherTerms: this.otherTerms,
    };

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default PaymentInfoModel;
