<template>
  <tr>
    <!-- No -->
    <td class="align_right">{{ index + 1 }}</td>
    <!-- 구분 -->
    <td class="align_center">
      <SelectBox
        :dataList="selectDataList"
        :value.sync="item.billingType"
        @onChangeItem="onChangeBillingType"
      />
    </td>
    <!-- 지급비율 -->
    <td>
      <InputRate
        :inputClass="'align_right'"
        :isValid="false"
        :value.sync="item.rate"
        :maxLength="7"
        :isDisabled="index === lastIndex || contractTotalPrice === 0"
      />
    </td>
    <!-- 지급금액 -->
    <td>
      <InputAutoMoney
        :inputClass="'align_right'"
        :maxLength="20"
        :value.sync="item.amount"
        :isDisabled="true"
      />
    </td>
    <td>
      <!-- :disabled="index === 0"  -->
      <button
        class="btn_small btn_fourthly"
        :disabled="lastIndex === 0"
        @click="removeInstallmentItem"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputRate from "@/components/common/input/InputRate";
import SelectBox from "@/components/common/selectBox/SelectBox";
import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "PaymentMethodRateLine",
  components: {
    InputAutoMoney,
    InputRate,
    SelectBox,
  },
  props: {
    item: Object,
    index: Number,
    lastIndex: Number,
    contractTotalPrice: Number,
  },
  data() {
    return {
      selectDataList: [
        { id: "D", code: "D", desc: "선급금" },
        { id: "S", code: "S", desc: "중도금" },
        { id: "B", code: "B", desc: "잔금" },
      ],
    };
  },
  computed: {
    isDisabled() {
      return this.index === 0 || this.index === this.lastIndex;
    },
  },
  watch: {
    item: {
      handler: function (newVal, oldVal) {
        if (this.index === this.lastIndex || this.contractTotalPrice === 0) return;
        this.calcAmount(newVal.rate);
        this.$emit("amountChange");
      },
      deep: true,
    },
  },
  created() {
    this.calcAmount(this.item.rate);
  },
  methods: {
    onChangeBillingType(value) {
      this.item.billingName = value.desc;
    },
    calcAmount(value) {
      const calcAmount = (this.contractTotalPrice * (value / 100)).toFixed();
      this.item.amount = addCommaForMoney(String(_.floor(calcAmount)));
    },
    removeInstallmentItem() {
      this.$emit("removeInstallmentItem", this.index);
    },
    onChangeSelect(to) {
      console.log(to);
    },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
