<template>
  <Popup popupType="tableType" width="984px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">입찰 선택</h3>
      </div>
      <div class="body_section">
        <OrderAnnoucePopupFilterBar @onClickSearch="onClickSearch" />
        <Board
          :dataList="dataList"
          :isScroll="true"
          scrollHeight="calc(100vh - 497px)"
          scrollMaxHeight="343px"
          scrollMinHeight="49px"
          :showPagination="false"
        >
          <template v-slot:colgroup>
            <col style="width: 100px" />
            <!-- 입찰 마감일 -->
            <col style="width: 136px" />
            <!-- 입찰 공고번호 -->
            <col style="width: 136px" />
            <!-- 발주번호 -->
            <col style="width: 190px" />
            <!-- 입찰 공고명 -->
            <col style="width: 120px" />
            <!-- 입찰 선정 업체 -->
            <col style="width: 128px" />
            <!-- 투찰 금액 -->
            <col />
            <!-- 구매 담당자 -->
          </template>
          <template v-slot:tr>
            <th style="width: 100px">입찰 마감일</th>
            <th style="width: 136px">입찰 공고번호</th>
            <th style="width: 136px">발주번호</th>
            <th style="width: 190px">입찰 공고명</th>
            <th style="width: 120px">입찰 선정 업체</th>
            <th style="width: 128px">투찰 금액</th>
            <th style="width: 150px">구매 담당자</th>
          </template>
          <template v-slot:rows>
            <template v-for="item in dataList">
              <ContractAnnouncePopupLine
                :key="item.tenderNum"
                :class="{ tr_select: itemSelected && itemSelected.tenderNum === item.tenderNum }"
                :rowData="item"
                @onClickRow="onClickRow"
              />
            </template>
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="7" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </Board>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button
        type="button"
        class="btn_secondary btn_medium"
        :disabled="!itemSelected"
        @click="onClickSelect"
      >
        선택
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import OrderAnnoucePopupFilterBar from "@/components/admin/order/popup/OrderAnnoucePopupFilterBar";
import Board from "@/components/common/board/Board";
import ContractAnnouncePopupLine from "./ContractAnnouncePopupLine";

import PopAnnounceSearchMixin from "@/mixins/PopAnnounceSearchMixin";

export default {
  name: "ContractAnnouncePopup",
  components: {
    Popup,
    OrderAnnoucePopupFilterBar,
    ContractAnnouncePopupLine,
    Board,
  },
  mixins: [PopAnnounceSearchMixin],
};
</script>
