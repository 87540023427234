import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

export default class ContractConditionModel {
  constructor() {
    // this.objRaw = null;

    this.subContractYn = false; // 하도급 여부.
    this.extendYn = false; // 계약서 자동 연장.
    this.bidYn = false; // 경쟁 비딩 여부.
    this.calculateYn = false; // 정산 여부.
    this.insuranceYn = false; // 계약이행보증보험

    this.bidCompany = ""; // 비딩 참여 업체들.

    this.contractType = "N";
  }

  setData(objRaw) {
    // this.objRaw = objRaw;

    const {
      subContractYn,
      extendYn,
      bidYn,
      calculateYn,
      bidCompany,
      contractType,
      contractSetting,
    } = objRaw;

    if (subContractYn || subContractYn === false) this.subContractYn = subContractYn;
    if (extendYn || extendYn === false) this.extendYn = extendYn;
    if (bidYn || bidYn === false) this.bidYn = bidYn;
    if (calculateYn || calculateYn === false) this.calculateYn = calculateYn;
    if (contractSetting || contractSetting == false) {
      this.insuranceYn = contractSetting.insuranceYn == "Y" ? true : false;
    }

    if (bidCompany) this.bidCompany = bidCompany;

    if (contractType) this.contractType = contractType;
  }

  getData() {
    // const obj = Object.assign( {}, this.objRaw );
    let obj = {};

    obj.subContractYn = this.subContractYn;
    obj.extendYn = this.extendYn;
    obj.bidYn = this.bidYn;
    obj.calculateYn = this.calculateYn;
    obj.insuranceYn = this.insuranceYn;

    if (this.bidCompany) obj.bidCompany = this.bidCompany;

    if (this.contractType) obj.contractType = this.contractType;

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}
