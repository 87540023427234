<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">
          계약 등록
          <span v-if="itemData.contractCid" class="txt_view">{{ itemData.contractCid }}</span>
        </h2>
      </div>
      <div class="body_section">
        <ContractSetting
          :isModifyMode="isModifyMode"
          :type="type"
          :templateName.sync="templateName"
          :purchaseWfDataList.sync="purchaseWfContract"
          :contractInfoModel.sync="contractInfoModel"
          :contractAddInfoModel.sync="contractAddInfoModel"
          :paymentMethodModel.sync="paymentMethodModel"
          :estimateWfDataList="estimateContract ? estimateContract : {}"
          @removeTemplateValue="removeTemplateValue"
          @onClickTemplateSelectPopup="onClickTemplateSelectPopup"
          @onClickEtcTemplatePop="onClickEtcTemplatePop"
          @onClickPurchaseRequestesSearch="onClickPurchaseRequestesSearch"
          @onClickEstimateRequestesSearch="onClickEstimateRequestesSearch"
          @onClickConnectPurchaseWfPost="onClickConnectPurchaseWfPost"
          @onClickRemovePurchaseReqeust="onClickRemovePurchaseReqeust"
          @onClickConnectEstimateWfPost="onClickConnectEstimateWfPost"
          @onClickRemoveEstimateReqeust="onClickRemoveEstimateReqeust"
          @onClickPopEstimatePrint="onClickPopEstimatePrint"
        />

        <ManagerInfo :managerInfoModel="managerInfoModel" />

        <!--협력사 정보-->
        <SupplyPartnerInfo
          :contractType="type"
          :model.sync="supplyPartnerInfoModel"
          @onClickPopPartnerSelect="onClickPopPartnerSelect"
        />
        <!-- 협력사 담당자 정보 -->
        <SupplyPartnerManagerInfoInput :contractType="type" :model.sync="supplyPartnerInfoModel" />

        <!-- 품목상세 -->
        <template v-if="type !== 'renewal'">
          <!-- 일반계약일떄 사용 -->
          <SupplyInfo
            :supplyInfoModel.sync="supplyInfoModel"
            :assetTypeName="assetTypeName"
            :attachFile.sync="attachFile"
          />
        </template>
        <template v-else>
          <!-- 변경계약일떄 사용 -->
          <SupplyInfoRenewal
            :type="type"
            :assetType="assetType"
            :assetTypeName="assetTypeName"
            :supplyInfoModel.sync="supplyInfoModel"
            :totalInspectAmount="totalInspectAmount"
            :attachFile.sync="attachFile"
            @onClickGoodsNamePopup="onClickGoodsNamePopup"
          />
        </template>

        <PaymentMethod
          :totalInspectAmount="totalInspectAmount"
          :paymentMethodModel.sync="paymentMethodModel"
          :contractModel.sync="contractInfoModel"
          :attachFile.sync="attachFile"
          :supplyInfoModel.sync="supplyInfoModel"
        />

        <AddInfo
          :supplyInfoModel.sync="supplyInfoModel"
          :attachFile="attachFile"
          :offlineYn.sync="offlineYn"
        />

        <template v-if="type === 'renewal'">
          <!-- 변경계약일떄 -->
          <TableViewWithTitle title="변경계약 사유">
            <template v-slot:body>
              <tr>
                <th>변경계약 사유<span class="ico_purchase ico_star">필수항목</span></th>
                <td colspan="3">
                  <Textarea
                    :value.sync="changeReason"
                    :maxLength="300"
                    :isCount="true"
                    placeholder="사유를 입력하세요."
                  />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickCancel">취소</button>
      </div>
      <div class="area_right">
        <template v-if="requestCid">
          <template v-if="type !== 'renewal'">
            <button v-if="contractStatus" class="btn_fourthly btn_large" @click="onClickPreviewPop">
              미리보기
            </button>

            <button class="btn_primary btn_large" @click="onClickProcess">임시저장</button>

            <template v-if="!offlineYn">
              <button class="btn_primary btn_large" @click="onClickConfirm">전자서명 요청</button>
            </template>
            <template v-else>
              <!-- offline 계약-->
              <button class="btn_primary btn_large" @click="onClickOfflineConfirm">
                전자서명 요청
              </button>
            </template>
          </template>
          <template v-else>
            <!--변경계약 버튼표기 여부-->
            <!-- <button class="btn_fourthly btn_large" @click="onClickPreviewPop">미리보기</button> -->
            <!-- <button class="btn_primary btn_large" @click="onClickProcess">임시저장</button> -->
            <button class="btn_primary btn_large" @click="onClickRenewalConfirm">
              변경계약 전자서명 요청
            </button>
          </template>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isAlertCancel"
        alertText="계약 정보 등록을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickAlertCancel"
        @onClickPrimary="onClickAlertOk"
      />
      <AlertPopup
        v-if="isAutoErrorAlert"
        :alertText="autoErrorAlertAddDesc || '정산일을 정확하게 입력해주세요'"
        btnPrimaryText="확인"
        @onClickPrimary="onClickConfirmErrorAlert"
      />
      <ContractAnnouncePopup
        v-if="isPopAnnounceSelect"
        @onClickClose="onClickClosePopAnnounceSelect"
        @onClickSelect="onClickSelectAnnounce"
      />
      <ContractPartnerPopup
        v-if="isPopPartnerSelect"
        @onClickClose="onClickClosePopParnterSelect"
        @onClickAdd="onClickAddPartner"
      />
      <DirectorLdapPopup
        v-if="isPopLdap"
        @onClickSelect="onClickSelectLdap"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
      <TemplateSelectPopup
        v-if="isPopTemplateSelect"
        :beforePage="'계약'"
        :template-gubun="'계약서'"
        :is-select-box-disabled="true"
        @onClickSubmit="onClickSubmitPopTemplateSelect"
        @onClickClose="onClickClosePopTemplateSelect"
      />
      <TemplatePreviewPopup
        v-if="isTemplatePopup"
        :previewId="previewId"
        @onClickClose="onClickClosePreviewPopup"
      />
      <TemplateEtcPopup
        v-if="isPopTemplateEtc"
        :receiver="receiver"
        :clientId="clientId"
        @onClickClose="onClickCloseEtcPopup"
      />
      <TemplateSignPopup
        v-if="isTemplateSignPopup"
        :contractId="resultId"
        :clientId="clientId"
        @onClickClose="onClickCloseSignPopup"
      />
      <PurchaseRequestsPopup
        v-if="isPopPurchaseRequests"
        :purchaseWfDataList.sync="purchaseWfContract"
        :type="'S'"
        @onClickClose="onClickClosePopPurchaseRequests"
        @setPurchaseContract="setPurchaseContract"
      />
      <EstimateRequestsPopup
        v-if="isPopEstimateRequests"
        :estimateWfDataList.sync="estimateContract"
        :type="'S'"
        @onClickClose="onClickClosePopEstimateRequests"
        @setEstimateContract="setEstimateContract"
      />
      <EstimatePrintPopup
        v-if="isPopEstimatePrint"
        :estimateNo="estimatePrint ? estimatePrint.quotationNum : null"
        :onlyCloseButton="true"
        @onClickClose="onClickClosePopEstimatePrint"
      />
      <PopGoodsNameSearch
        v-if="isPopGoodsName"
        :selectedGoodsName="selectedGoodsName"
        @onClickClose="onClickCloseGoodsNamePopup"
        @onClickComplete="onClickCompleteGoodsNamePopup"
      />
      <!--@update:purchaseWfDataList="updatePurchaseWfDataList"-->
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Textarea from "@/components/common/textarea/Textarea";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Sticky from "@/components/layout/content/Sticky.vue";

import AlertPopup from "@/components/layout/popup/AlertPopup";

import ContractSetting from "@/components/admin/contract/write/ContractSetting";

import ContractInfo from "@/components/admin/contract/write/ContractInfo";
import ContractInfoModel from "@/components/admin/contract/write/ContractInfoModel";
import PaymentMethod from "@/components/admin/contract/write/PaymentMethod";
import AddPaymentMethod from "@/components/admin/contract/write/AddPaymentMethod";
import SpecialAgreement from "@/components/admin/contract/write/SpecialAgreement";
import ManagerInfo from "@/components/admin/contract/write/ManagerInfo";
import ManagerInfoModel from "@/components/admin/contract/write/ManagerInfoModel";
import SupplyPartnerInfo from "@/components/admin/contract/write/SupplyPartnerInfo";
import SupplyPartnerManagerInfoInput from "@/components/admin/contract/write/SupplyPartnerManagerInfoInput";

import SupplyPartnerInfoModel from "@/components/admin/contract/write/SupplyPartnerInfoModel";

import SupplyInfo from "@/components/admin/contract/write/SupplyInfo";
import SupplyInfoRenewal from "@/components/admin/contract/write/SupplyInfoRenewal";

import ContractCondition from "@/components/admin/contract/write/ContractCondition";
import ContractConditionModel from "@/components/admin/contract/write/ContractConditionModel";
import PaymentInfo from "@/components/admin/contract/write/PaymentInfo";
import PaymentInfoModel from "@/components/admin/contract/write/PaymentInfoModel";
import AddInfo from "@/components/admin/contract/write/AddInfo";
import AddInfoModel from "@/components/admin/contract/write/AddInfoModel";

import SettleUnitCost from "@/components/admin/contract/write/SettleUnitCost";
import SettleUnitCostModel from "@/components/admin/contract/write/SettleUnitCostModel";
import SettleInfo from "@/components/admin/contract/write/SettleInfo";
import SettleInfoModel from "@/components/admin/contract/write/SettleInfoModel";
import ContractAddInfoModel from "@/components/admin/contract/write/ContractAddInfoModel";

import SupplyInfoModel from "@/components/admin/contract/write/SupplyInfoModel";

import PaymentMethodModel from "@/components/admin/contract/write/PaymentMethodModel";
import PaymentMethodRateLineModel from "@/components/admin/contract/write/PaymentMethodRateLineModel";

import ContractAnnouncePopup from "@/components/admin/contract/popup/ContractAnnouncePopup";
import ContractPartnerPopup from "@/components/admin/contract/popup/ContractPartnerPopup";
import DirectorLdapPopup from "@/components/admin/system/director/popup/DirectorLdapPopup";
import TemplateSelectPopup from "@/components/shared/template/TemplateSelectPopup";
import TemplatePreviewPopup from "@/components/shared/template/TemplatePreviewPopup.vue";
import TemplateEtcPopup from "@/components/shared/template/TemplateEtcPopup.vue";
import TemplateSignPopup from "@/components/shared/template/TemplateSignPopup.vue";
import PurchaseRequestsPopup from "@/components/shared/purchaseRequestPopup/PurchaseRequestsPopup";
import EstimateRequestsPopup from "@/components/shared/estimateRequestPopup/EstimateRequestsPopup";
import EstimatePrintPopup from "@/components/admin/estimate/popup/EstimatePrintPopup";

import PopGoodsNameSearch from "@/components/admin/draft/write/popup/PopGoodsNameSearch.vue";

import PageMixin from "@/mixins/PageMixin";

import { GET_CONTRACT_WRITE } from "@/store/modules/contract/action";

import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";

import { addCommaForMoney, getMoneyToNumber, getMoneyToKorean } from "@/utils/stringUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "ContractInfoWrite",
  components: {
    PageWithLayout,
    Textarea,
    Sticky,
    AlertPopup,
    TableViewWithTitle,

    ContractSetting,
    // PurchaseJiraConnectWrite,
    ContractInfo,
    PaymentMethod,
    AddPaymentMethod,
    SpecialAgreement,
    ManagerInfo,
    SupplyPartnerInfo,
    SupplyPartnerManagerInfoInput,
    SupplyInfo,
    SupplyInfoRenewal, //변경계약

    ContractCondition,
    SettleUnitCost,
    SettleInfo,
    PaymentInfo,
    AddInfo,

    ContractAnnouncePopup,
    ContractPartnerPopup,
    DirectorLdapPopup,
    TemplateSelectPopup,
    TemplatePreviewPopup,
    TemplateEtcPopup,
    TemplateSignPopup,
    PurchaseRequestsPopup,
    EstimateRequestsPopup,
    EstimatePrintPopup,

    PopGoodsNameSearch,
  },
  //mixins: [PageMixin, ReferenceMixin],
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
      isModifyMode: false,
      contractId: null,

      templateName: "",
      templateId: "",

      assetType: "",
      assetTypeName: "",

      requestData: null,
      requestCid: null, // 구매요청서 번호.
      estimateNum: null, // 견적요청서 번호
      type: null,
      requestContractList: [],
      changeReason: "", //변경계약 사유,
      totalInspectAmount: 0,

      selectedGoodsName: {},
      supplyInfoTargetIndex: null,

      signUseYn: "Y", // 계약서 템플릿 사용여부(전자서명 사용여부)
      performanceUseYn: "N",
      normalYn: "N", //별도 템플릿 사용여부

      contractIdForPreview: "",

      purchaseWfContract: {},
      estimateContract: {},

      specialAgreement: "",
      offlineYn: false,
      contractInfoModel: new ContractInfoModel(),
      managerInfoModel: new ManagerInfoModel(),

      supplyPartnerInfoModel: new SupplyPartnerInfoModel(),
      // partnerInfo: { companyName: '', name: ''},
      serialNum: null,
      contractConditionModel: new ContractConditionModel(),
      paymentInfoModel: new PaymentInfoModel(),
      addInfoModel: new AddInfoModel(),
      settleUnitCostModel: new SettleUnitCostModel(),
      settleInfoModel: new SettleInfoModel(),
      contractAddInfoModel: new ContractAddInfoModel(),
      supplyInfoModel: new SupplyInfoModel(),

      paymentMethodModel: new PaymentMethodModel(),
      paymentMethodRateLineModel: new PaymentMethodRateLineModel(),

      isPopAnnounceSelect: false,
      isPopPartnerSelect: false,
      isAutoErrorAlert: false,
      autoErrorAlertAddDesc: "",
      isPopLdap: false,
      popLdapTarget: "",

      isPopEstimatePrint: false,
      isPopTemplateSelect: false,
      isPopTemplateEtc: false,
      isTemplateSignPopup: false,
      clientId: "",

      selectedCompany: "",
      isAlertCancel: false,
      isPopGoodsName: false,

      INPUT_MAX_LENGTH: 120,

      insuranceYn: false,
      isTemplatePopup: false,
      contractStatus: "",

      // resultId: '-',

      necessaryValue: {},
      receiver: [],
      resultId: "", // 임시) 별도템플릿 검토 완료 후 callback 되는 contractId
      beforeWritingPage: "",
      estimatePrint: null,

      isPopPurchaseRequests: false,
      isPopEstimateRequests: false,

      previewId: null,
      attachFile: {
        electronicDocumentFile: [], //전자서명파일
        purchaseOrderFile: [], //발주서
        estimateFile: [], //견적서
        contractFile: [], //계약서
        etcAttachFile: [], //기타파일
        advancePaymentSuretyAttachFile: [], //선급금이행증서
        contractSuretyAttachFile: [], //계약이행증서
        defectSuretyAttachFile: [], //하자이행증권
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.contractId = this.$route.params.id;

    this.isModifyMode = this.$route.meta.isModifyMode;
    if (this.$route.meta.isRenewalMode) {
      //변경계약 일떄
      const { contractId, type } = this.$route.params;
      if (!this.$route.params.type) {
        this.alert("잘못된 접근입니다.", () => {
          this.$router.push({ path: this.$routerPath.CONTRACT_LIST });
        });
        return;
      }
      this.type = type;
      this.contractId = contractId;
      this.getRenewalData(this.contractId);
    } else {
      if (this.isModifyMode) {
        //수정
        this.getData(this.contractId);
      } else {
        //신규
        //this.setDefaultTemplate("계약서");
        const { requestCid, estimateNum, type } = this.$route.params;
        if (requestCid) {
          const { requestCid, estimateNum, type } = this.$route.params;
          this.requestCid = requestCid;
          this.estimateNum = estimateNum;
          this.type = type;

          switch (this.type) {
            case "estimate":
              this.getEstimateRequest();
              break;
            case "purchase":
              this.getPurchaseRequest();
              break;
          }
        } //this.beforeWritingPage = beforeWritingPage;
      }
    }
  },

  methods: {
    async contractInfo(data) {
      const { contractTemplateId, contractTemplateName, purchaseRequestDetail, estimateSearch } =
        data;
      const {
        assetType,
        assetTypeName,
        title,
        requesterId,
        requesterName,
        requestDeptName,
        requesterTel,
        requesterEmail,
        requesterPersonNum,
        totalPrice,
        purchaseGoodsList,
        projectCode,
        projectName,
      } = purchaseRequestDetail;
      //this.templateId = contractTemplateId;
      //this.templateName = contractTemplateName;
      this.projectCode = projectCode;
      this.projectName = projectName;
      this.purchaseWfContract = _.cloneDeep(purchaseRequestDetail);
      this.estimateContract = _.cloneDeep(estimateSearch);
      this.assetType = assetType;
      this.assetTypeName = assetTypeName;
      this.managerInfoModel.setBManager({
        requesterId,
        requesterName,
        requesterTel,
        requesterEmail,
        requesterPersonNum,
      });
      this.managerInfoModel.setIManager({
        requesterId,
        requesterName,
        requesterTel,
        requesterEmail,
        requesterPersonNum,
      });

      const convertContractInfoObj = {
        description: title, //계약명
        commonReqName: requesterName, // 예약요청자
        contractDept: requestDeptName, //계약요청부서1
        requestTotalPrice: totalPrice, //구매요청서에서 가져오는 토탈금액
        finalPrice: String(totalPrice), // 최종계약금액
        projectCode: projectCode,
        projectName: projectName,
      };

      this.contractInfoModel.setData(convertContractInfoObj);

      this.paymentMethodModel.balance = String(totalPrice);

      this.supplyInfoModel.setOrderGoodsList(purchaseGoodsList);
    },
    async getPurchaseRequest() {
      // 구매요청상세에서 계약관리 버튼 눌어 들어 왓을떄에 데이터 처리
      // 구매요청서 선택시 데이터 처리
      const path = `${this.$apiPath.ST_COMM_REQUEST}/${this.type}/${this.requestCid}`;
      const result = await ApiService.shared.getData(`${path}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.contractInfo(data);
    },

    async getEstimateRequest() {
      const path = `${this.$apiPath.ST_COMM_REQUEST}/${this.type}/${this.estimateNum}`;
      const result = await ApiService.shared.getData(`${path}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.contractInfo(data);
      this.getParnerData(data);
    },

    async getData(id) {
      const result = await ApiService.shared.getData(`${this.$apiPath.CONTRACT}/${id}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      if (data.contractStatus === "C") {
        this.alert("계약이 체결되었습니다.", () => {
          this.$router.push({
            path: `${this.$routerPath.CONTRACT_VIEW}/${id}`,
          });
        });
      }
      this.setData(data);

      //this.dispatchContractWrite();
    },
    async getRenewalData(id) {
      const result = await ApiService.shared.getData(`${this.$apiPath.CONTRACT}/renewal/${id}`);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      const { description, ...rest } = data;

      this.setData({ description: `[변경계약] ${description}`, ...rest });
    },

    async getParnerData(val) {
      const { name, companyName } = val.estimateSearch.selectionQuotation;
      const path = `${this.$apiPath.PARTNER_PERSON_LIST}?name=${name}&companyName=${companyName}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.supplyPartnerInfoModel.setData(data[0]);
    },

    checkRequesterLdap() {
      if (!this.requestData) return;

      const { requester } = this.requestData;
      if (!requester) return;

      const arr = requester.split("(");
      if (arr.length !== 2) return;

      const requesterId = arr[0];
      if (!requesterId) return;

      const arrRest = arr[1].split(")");
      if (arrRest.length < 1) return;

      const requesterName = arrRest[0];

      this.getLdapData(requesterId, requesterName);
    },
    async getLdapData(requesterId, requesterName) {
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${requesterId}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        // this.alert( result.text );
        return;
      }

      const requester = result.data.find((item) => item.personName === requesterName);
      if (!requester) return;

      // 계약 요청자
      this.contractRequester = {
        code: "B",
        email: requester.emailId,
        loginId: requester.accountId,
        name: requester.personName,
        tel: requester.officePhone,
      };

      this.contractInfoModel.setContractDept(requester.deptPathName);
    },
    setData(rawData) {
      this.itemData = rawData;
      this.attachFile = { ...this.attachFile, ...rawData.attachFile };
      this.requestCid = rawData.purchaseWfContract.requestCid;
      this.purchaseWfContract = rawData.purchaseWfContract || {};
      this.estimateContract = rawData.estimateContract || {};
      this.contractInfoModel.setData(rawData);
      this.paymentInfoModel.setData(rawData);
      this.contractAddInfoModel.setData(rawData);
      this.paymentMethodModel.setData(rawData.orderPayment);

      //this.$refs.contractInfo.setCategoryForce();

      this.managerInfoModel.setData(rawData);

      //품목상세
      this.supplyInfoModel.setData({
        orderGoodsList: rawData.orderGoodsList,
        ...rawData.orderGoodsAddInfo,
        orderInspectedGoodsList: rawData.orderInspectedGoodsList,
      });

      //this.setDataBManager(rawData);

      this.supplyPartnerInfoModel.setData(rawData.contractPerson);

      this.contractConditionModel.setData(rawData);

      this.settleUnitCostModel.setData(rawData);

      this.settleInfoModel.setData(rawData);

      //this.addInfoModel.setData(rawData.orderGoodsAddinfo);

      this.signUseYn =
        rawData.contractSetting.signatureYn == "" ? "N" : rawData.contractSetting.signatureYn;

      this.performanceUseYn =
        rawData.contractSetting.evaluateYn == "" ? "N" : rawData.contractSetting.evaluateYn;

      if (rawData.contractSetting.normalYn) {
        this.normalYn = rawData.contractSetting.normalYn;
      }

      this.insuranceYn = this.contractConditionModel.insuranceYn;

      this.contractStatus = rawData.contractStatus;

      if (this.itemData.templateName) {
        this.templateName = this.itemData.templateName;
      }

      if (this.itemData.templateId) {
        this.templateId = this.itemData.templateId;
      }

      if (this.itemData.resultId) {
        this.resultId = this.itemData.resultId;
      }

      this.requestContractList = this.itemData.requestContractList || [];

      if (rawData.templateId) {
        const path = `${this.$apiPath.TEMPLATE}/select?templateId=${rawData.templateId}`;
        this.callNecessaryValue(path);
      }
      if (this.itemData.totalInspectAmount) {
        this.totalInspectAmount = this.itemData.totalInspectAmount;
      }
      if (this.itemData.offlineYn) {
        this.offlineYn = this.itemData.offlineYn;
      }
      if (this.itemData.changeReason) {
        this.changeReason = this.itemData.changeReason;
      }
    },
    getObjectForSave() {
      let obj = Object.assign({}, this.itemData);
      obj.contractStatus = "P"; // 기본값 작성중

      Object.assign(obj, this.contractInfoModel.getData());
      Object.assign(obj, this.managerInfoModel.getData());

      const objPartnerInfo = this.supplyPartnerInfoModel.getData();
      obj.contractPerson = objPartnerInfo;
      if (this.serialNum) {
        obj.contractPerson.serialNum = this.serialNum;
      }

      const { orderGoodsList, ...supplyInfoModelRest } = this.supplyInfoModel.getData();

      Object.assign(obj, { orderGoodsList: orderGoodsList }, { ...supplyInfoModelRest });
      obj.finalPrice = supplyInfoModelRest.orderGoodsAddInfo.totalContractAmount;
      obj.finalPriceKr = getMoneyToKorean(
        supplyInfoModelRest.orderGoodsAddInfo.totalContractAmount,
      );

      // const { orderPartialPayments, ...paymentMethodModelRest } = this.paymentMethodModel.getData();

      Object.assign(obj, { ...this.paymentMethodModel.getData() });

      if (this.contractRequester) {
        obj.contractManagerList.push(this.contractRequester);
      }

      obj.purchaseWfContract = {
        requestCid: this.purchaseWfContract.requestCid,
      };
      obj.estimateContract = {
        estimateNum: this.estimateContract.estimateNum,
      };

      Object.assign(obj, this.contractConditionModel.getData());

      // if (this.contractConditionModel.calculateYn) {
      //   Object.assign(obj, this.settleUnitCostModel.getData());
      //   Object.assign(obj, this.settleInfoModel.getData());
      // }

      const paymentInfo = this.paymentInfoModel.getData();

      Object.assign(obj, paymentInfo);

      //Object.assign(obj, { orderGoodsAddinfo: this.addInfoModel.getData() });

      // 표준화 제거
      //if (purchaseOrderFile || contractFile) {
      //  obj.contractStatus = 'C';
      //}
      obj.contractStatus = "P";

      // if (!this.isModifyMode) {
      //   const userData = LocalStorageManager.shared.getUserData();
      //   obj.managerNum = userData.managerNum;
      // }

      if (!obj.projectYM) {
        const now = new Date();
        obj.projectYM = `${now.getFullYear()}${now.getMonth() - 1}`;
      }

      let setObj = {};
      setObj.signatureYn = this.signUseYn;
      setObj.evaluateYn = this.performanceUseYn;
      setObj.insuranceYn = this.contractConditionModel.insuranceYn == true ? "Y" : "N";
      setObj.normalYn = this.normalYn;

      obj.contractSetting = setObj;

      if (this.signUseYn == "Y") {
        obj.templateId = this.templateId;
        obj.templateName = this.templateName;
        if (this.normalYn == "N") obj.resultId = this.resultId;
      }

      let contractAddInfoObj = this.contractAddInfoModel.getData();
      obj.contractAddInfo = contractAddInfoObj;

      obj.requestContractList = this.requestContractList || [];

      //모든 첨부파일 추가
      obj.attachFile = this.attachFile;

      obj.changeReason = this.changeReason;
      obj.offlineYn = this.offlineYn;

      return obj;
    },
    setDataBManager(rawData) {
      const { contractManagerList } = rawData;
      if (!contractManagerList) return;

      const bManager = contractManagerList.find((item) => item.code === "B");
      if (!bManager) return;

      if (!bManager.loginId) bManager.loginId = "";

      this.contractRequester = bManager;
    },
    onClickPopAnnounceSelect() {
      this.isPopAnnounceSelect = true;
    },
    onClickClosePopAnnounceSelect() {
      this.isPopAnnounceSelect = false;
    },
    onClickSelectAnnounce(rowData) {
      this.isPopAnnounceSelect = false;

      this.contractInfoModel.setDataFromAnnounceSelect(rowData);
    },

    onClickPopPartnerSelect() {
      this.isPopPartnerSelect = true;
    },
    onClickClosePopParnterSelect() {
      this.isPopPartnerSelect = false;
    },
    onClickAddPartner(rowData) {
      this.isPopPartnerSelect = false;

      // 제공 데이터 중 mobile을 기존 tel에 사용하기로.
      rowData.tel = rowData.mobile;

      this.supplyPartnerInfoModel.setData(rowData);

      // this.partnerInfo = rowData;

      // 최초 생성시 serialNum 은 없음으로 예외처리
      // if(!this.partnerInfo) {
      //   //생성시
      //   this.partnerInfo = rowData;
      // }else{
      //   //수정시
      //   const {serialNum} = this.partnerInfo;
      //   this.partnerInfo = Object.assign(rowData, {serialNum});
      // }
    },
    settleInfoErrorAlert(e) {
      this.isAutoErrorAlert = true;
      this.autoErrorAlertAddDesc = e;
    },
    onClickSelectLdap(rowData) {
      if (this.popLdapTarget == "contractRequester") {
        this.isPopLdap = false;

        this.managerInfoModel.setBManager({
          requesterEmail: rowData.emailId,
          requesterId: rowData.accountId,
          requesterName: rowData.personName,
          tel: rowData.tel,
        });

        this.contractInfoModel.setContractDept(rowData.deptName);
      } else if (this.popLdapTarget == "0" || this.popLdapTarget == "1") {
        const { deptName } = rowData;
        // const isKakao = LocalStorageManager.shared.getIsKakao();
        // if(isKakao && deptName.indexOf('업무용') > -1 ) {
        //   this.alert('업무용계정은 선택하실 수 없습니다.');

        //   return;
        // }

        this.isPopLdap = false;

        // 검수 담당자
        // const targetIndex = this.popLdapTarget;
        // let target = this.managerInfoModel.managerList[targetIndex]; // InspectManagerModel.

        // target.loginId = rowData.accountId;
        // target.name = rowData.personName;

        target.tel.setDataByString(rowData.tel);
        target.email.setDataByString(rowData.emailId);
      }
    },
    onClickPopLdap(target) {
      this.isPopLdap = true;
      this.popLdapTarget = target;
    },
    onClickClosePopLdap() {
      this.isPopLdap = false;
    },
    onClickTemplateSelectPopup() {
      this.isPopTemplateSelect = true;
    },
    onClickClosePopTemplateSelect() {
      this.isPopTemplateSelect = false;
    },
    onClickSubmitPopTemplateSelect(selectedTemplate) {
      this.isPopTemplateSelect = false;
      this.templateName = selectedTemplate.templateName;
      this.templateId = selectedTemplate.templateId;

      const path = `${this.$apiPath.TEMPLATE}/select?templateId=${selectedTemplate.templateId}`;
      this.callNecessaryValue(path);
    },
    async callNecessaryValue(path) {
      const result = await ApiService.shared.getData(path);

      if (result.code !== "200") {
        this.alert(result.text);

        return;
      }

      const valueObj = {};
      if (result.data.length > 0) {
        result.data.forEach((item) => {
          valueObj[item] = true;
        });
      }

      this.necessaryValue = valueObj;
    },
    onClickConfirmErrorAlert() {
      this.isAutoErrorAlert = false;
    },
    onClickConfirm() {
      const params = {
        text: "전자서명 요청을 하시겠습니까?",
        onClickY: this.contractInspect,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickOfflineConfirm() {
      const params = {
        text: "offline계약입니다.<br /> 계약을 진행하시겠습니까?<br /><strong style='color:red'>offline계약은 전자서명 요청없이 전자결재로 넘어갑니다.</strong>",
        onClickY: this.contractInspect,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },

    async onClickPopEstimatePrint(item) {
      item.quotationList.forEach((company) => {
        if (company.status == "S") {
          this.selectedCompany = company;
        }
      });

      const path = `${this.$apiPath.QUOTATION}/${this.selectedCompany.quotationNum}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.estimatePrint = data;
      this.isPopEstimatePrint = true;
    },

    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },

    onClickProcess() {
      this.contractProcess();
    },

    contractProcess() {
      //임시저장

      const alertMessage = this.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      const obj = this.getObjectForSave();
      obj.contractStatus = "P";

      this.callAdd(obj);
    },
    contractInspect() {
      const alertMessage = this.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      const obj = this.getObjectForSave();
      obj.contractStatus = "I";

      this.callAdd(obj);
    },
    async contractRenewal() {
      //변경계약

      const obj = this.getObjectForSave();
      obj.contractStatus = "I";
      const result = await ApiService.shared
        .postData(`${this.$apiPath.CONTRACT}/renewal/${this.contractId}`, obj)
        .then((response) => {
          const { code, data, text } = response;
          console.log("변경계약code,text,data :>> ", code, text, data);
          if (code !== "200") {
            this.alert(text);
            return;
          }

          if (data.resultCode === "6001") {
            this.alert(data.message, () => {
              const afterPath = `${this.$routerPath.CONTRACT_VIEW}/${data.contractCid}`;
              this.$router.push({
                path: afterPath,
              });
            });

            return;
          }
          ///data.resultCode === 2000 이면 성공

          this.$router.push({
            path: `${this.$routerPath.CONTRACT_VIEW}/${data.contractId}`,
          });
        });
    },
    async callAdd(obj) {
      if (this.isModifyMode) {
        const result = await ApiService.shared
          .putData(`${this.$apiPath.CONTRACT}/${this.contractId}`, obj)
          .then((response) => {
            const { code, text } = response;

            if (code !== "200") {
              this.alert(text);
              return;
            }

            this.refreshPage(text);
          });
      } else {
        const result = await ApiService.shared
          .postData(this.$apiPath.CONTRACT, obj)
          .then((response) => {
            const { code, text, data } = response;
            console.log("신규생성code,text,data :>> ", code, text, data);
            if (code !== "200") {
              this.alert(text);
              return;
            }

            if (data.resultCode === "6001") {
              this.alert(data.message, () => {
                const afterPath = `${this.$routerPath.CONTRACT_VIEW}/${data.contractCid}`;
                this.$router.push({
                  path: afterPath,
                });
              });

              return;
            }

            ///data.resultCode === 2000 이면 성공

            this.$router.push({
              path: `${this.$routerPath.CONTRACT_VIEW}/${data.contractId}`,
            });
          });
      }
    },
    refreshPage(text) {
      const contractId = this.contractId ? this.contractId : text;
      // 저장 성공시 해당 화면 refresh
      // const afterPath = this.contractId ? `${ this.$routerPath.CONTRACT_VIEW }/${ this.contractId }` : this.$routerPath.CONTRACT_LIST;
      const afterPath = `${this.$routerPath.CONTRACT_VIEW}/${contractId}`;
      this.$router.push({
        path: afterPath,
      });
    },
    getAlertMessage() {
      let alertMessage;
      if (!this.templateId || !this.templateName) {
        return "계약서 템플릿을 선택해주세요";
      }

      alertMessage = this.contractInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.contractAddInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.supplyPartnerInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.supplyPartnerInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.supplyInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.paymentMethodModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      if (this.offlineYn && this.attachFile.contractFile.length < 1) {
        alertMessage = "계약서 첨부파일에 offline계약서를 첨부해주세요.";
        if (alertMessage) return alertMessage;
      }

      return null;
    },
    onClickCancel() {
      this.isAlertCancel = true;
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickAlertOk() {
      const nextPath =
        this.beforeWritingPage != "" ? this.beforeWritingPage : this.$routerPath.CONTRACT_LIST;

      this.$router.push({
        path: nextPath,
      });
    },

    async onClickPreviewPop() {
      const obj = this.getObjectForSave();
      const url = `${this.$apiPath.CONTRACT}/template/preview`;
      const result = await ApiService.shared.postData(url, obj);
      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.previewId = data.previewId;
      //this.$windowOpen(`/templatePreview?previewId=${this.previewId}`);
      this.isTemplatePopup = true;
    },

    onClickClosePreviewPopup() {
      this.isTemplatePopup = false;
      this.previewId = "";
    },

    removeTemplateValue() {
      if (this.templateName && this.templateId) {
        this.templateName = "";
        this.templateId = "";
      }
    },
    onClickEtcTemplatePop() {
      this.callGetClientId(this.supplyPartnerInfoModel.getData());
    },
    async callGetClientId(partnerData) {
      const url = `${this.$apiPath.CONTRACT}/clientId`;
      const result = await ApiService.shared.getData(url);

      if (result.code !== "200" || result.text == "") {
        this.alert("별도템플릿을 생성할 수 있는 구성원이 아닙니다.");
        return;
      }

      this.clientId = result.text;
      this.openEtcTemplatePop(partnerData, result);
    },
    openEtcTemplatePop(partnerData) {
      const loginUser = LocalStorageManager.shared.getUserData();
      let loginObj = {};
      loginObj.name = loginUser.name ? loginUser.name : "";
      loginObj.phone = loginUser.tel ? loginUser.tel : "";
      loginObj.email = loginUser.email ? loginUser.email : "";

      this.receiver.push(loginObj);

      let { name, tel, email } = partnerData;
      let obj = {};
      obj.name = name ? name : "";
      obj.phone = tel ? tel : "";
      obj.email = email ? email : "";

      this.receiver.push(obj);

      this.isPopTemplateEtc = true;
    },
    onClickCloseEtcPopup(result) {
      if (result) {
        this.resultId = result.contractId ? result.contractId : "";
        this.templateName = "별도템플릿";
        this.templateId = "-";
      }

      this.receiver = [];
      this.isPopTemplateEtc = false;
      this.isCompleteEtcPopup = true;

      if (this.resultId != "") {
        this.isTemplateSignPopup = true;
      }
    },
    onClickCloseSignPopup(result) {
      this.isTemplateSignPopup = false;
    },
    // 구매요청서 정보 불러오기
    onClickPurchaseRequestesSearch() {
      this.isPopPurchaseRequests = true;
    },
    // 구매요청서 선택 팝업 닫기
    onClickClosePopPurchaseRequests() {
      this.isPopPurchaseRequests = false;
    },
    // 견적서 정보 불러오기
    onClickEstimateRequestesSearch() {
      this.isPopEstimateRequests = true;
    },
    // 견적서 선택 팝업 닫기
    onClickClosePopEstimateRequests() {
      this.isPopEstimateRequests = false;
    },
    // 구매요청서 항목 연결
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    // 구매요청서 항목 삭제
    onClickRemovePurchaseReqeust() {
      this.purchaseWfContract = {};
      this.estimateContract = {};
      this.type = null;
      this.requestContractList = [];
      this.supplyPartnerInfoModel = new SupplyPartnerInfoModel();
      this.contractInfoModel.finalPrice = "";
      this.contractInfoModel = new ContractInfoModel();
      this.paymentMethodModel = new PaymentMethodModel();
      // this.paymentMethodModel.downPaymentRate = '0';
      // this.paymentMethodModel.middlePaymentRate = '0';
      // this.paymentMethodModel.balance = '0';
    },
    // 구매요청서 항목 연결
    onClickConnectEstimateWfPost(item) {
      this.$windowOpen(`${this.$routerPath.ESTIMATE_VIEW}/${item.estimateNum}`);
    },
    // 견적서 항목 삭제
    onClickRemoveEstimateReqeust() {
      this.estimateContract = {};
      this.type = "purchase";
      this.requestContractList = [];
      this.contractInfoModel.finalPrice = "";
      this.supplyPartnerInfoModel = new SupplyPartnerInfoModel();
      this.contractInfoModel = new ContractInfoModel();
      this.paymentMethodModel = new PaymentMethodModel();
      // this.paymentMethodModel.downPaymentRate = '0';
      // this.paymentMethodModel.middlePaymentRate = '0';
      // this.paymentMethodModel.balance = '0';
    },
    async fetchDraftRequestData(requestCid) {
      const res = await ApiService.shared.getData(
        `${this.$apiPath.DRAFT_LIST}?requestCid=${requestCid}`,
      );

      if (parseInt(res.code) === 200 && res.data.length > 0) {
        const { title, requester } = res.data[0];
        this.purchaseWfContract = { requestCid, title, requester };
        // this.estimateContract = { requestCid, title, requester };
      }
    },
    updatePurchaseWfDataList(item) {
      this.requestContractList = [item];

      // this.contractRequester = {
      //   code: 'B',
      //   email: item.emailId,
      //   loginId: item.accountId,
      //   name: item.requesterName,
      //   tel: item.officePhone,
      // };
    },
    updateEstimateWfDataList(item) {
      this.requestContractList = [item];

      // this.contractRequester = {
      //   code: 'B',
      //   email: item.emailId,
      //   loginId: item.accountId,
      //   name: item.requesterName,
      //   tel: item.officePhone,
      // };
    },
    setPurchaseContract(item) {
      // console.log(item);
      const { requestCid } = item;
      this.requestCid = requestCid;
      this.type = "purchase";
      this.getPurchaseRequest();
    },
    setEstimateContract(item) {
      // console.log(item);
      const { estimateNum } = item;
      this.estimateNum = estimateNum;
      // this.requestCid = estimateNum;
      this.type = "estimate";
      this.getEstimateRequest();
    },
    // async setPurchaseInfo(type, requestCid) {
    //   const obj = await this.getPurchaseContract(requestCid);
    //   this.purchaseWfContract = obj;
    //   this.setPurchaseContract(obj);
    //
    //   // 구매요청 정보 가져옴
    //   const purchaseInfo = await this.getReferenceInfo(type, requestCid);
    //   console.log(purchaseInfo);
    //   if (purchaseInfo) {
    //   }
    //   // getPurchaseContract
    // },
    // 계약서 자동입력
    async setDefaultTemplate(templateGubun, templateId = null) {
      const path = `${this.$apiPath.TEMPLATE_LIST}?&page=1&size=1&templateGubun=${templateGubun}`;
      const result = await ApiService.shared.getData(path);
      if (result.data && result.data[0]) {
        if (templateId) {
          // 있으면 filter
          const filter = result.data.filter((item) => item.templateId === templateId);
          if (filter.length > 0) {
            this.onClickSubmitPopTemplateSelect(filter[0]);
          }
        } else {
          // 없으면 첫번쨰
          this.onClickSubmitPopTemplateSelect(result.data[0]);
        }
      }
    },
    onClickGoodsNamePopup(index) {
      this.isPopGoodsName = true;
      this.supplyInfoTargetIndex = index;
    },
    onClickCloseGoodsNamePopup() {
      this.isPopGoodsName = false;
      this.supplyInfoTargetIndex = null;
    },
    onClickCompleteGoodsNamePopup(selectedGoods) {
      const SupplyInfoModelItem = this.supplyInfoModel.orderGoodsList[this.supplyInfoTargetIndex];
      SupplyInfoModelItem.setData(selectedGoods);
      this.isPopGoodsName = false;
      this.supplyInfoTargetIndex = null;
    },

    onClickRenewalConfirm() {
      const alertMessage = this.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      if (!this.changeReason) {
        this.alert("변경계약 사유를 입력해주세요");
        return;
      }
      this.contractRenewal();
    },
  },
};
</script>
