import ApiService from "@/services/ApiService";

import { dateToString } from "@/utils/dateUtils";

const PopAnnounceSearchMixin = {
  data() {
    return {
      dataList: [],
      itemSelected: null,
    };
  },
  beforeMount() {
    const path = `${this.$apiPath.BID_PARTNERS}`;

    this.search(path);
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSearch(obj) {
      const { dates, radioSelectedId, inputCurrentText } = obj;

      let path = `${this.$apiPath.BID_PARTNERS}`;

      const hasSearchWord = !!inputCurrentText.trim();

      if (hasSearchWord) {
        path += `?${radioSelectedId}=${inputCurrentText}`;
      }

      if (dates.length === 2) {
        const startDate = dateToString(dates[0]);
        const endDate = dateToString(dates[1]);

        path += hasSearchWord ? "&" : "?";

        path += `startDate=${startDate}&endDate=${endDate}`;
      }

      this.search(path);
    },
    async search(path) {
      this.itemSelected = null;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.dataList = [];
        return;
      }

      this.dataList = result.data;
    },
    onClickRow(rowData) {
      this.itemSelected = rowData;
    },
    onClickSelect() {
      this.$emit("onClickSelect", this.itemSelected);
    },
  },
};
export default PopAnnounceSearchMixin;
