<template>
  <TableViewWithTitle title="협력사 정보">
    <template v-slot:headRight>
      <div class="group_form">
        <button
          v-if="disabeldPartnerSelect"
          type="button"
          class="btn_secondary btn_medium"
          @click="onClickPopPartnerSelect"
        >
          업체 선택
        </button>
      </div>
    </template>
    <template v-slot:body>
      <tr>
        <th>업체명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <button
            v-if="model.userNum"
            class="link_subject"
            @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${model.userNum}`)"
          >
            {{ model.companyName || "" }}
          </button>
          <span v-else>{{ model.companyName }}</span>

          <!-- <Input :isDisabled="true" :value.sync="model.companyName" /> -->
        </td>
        <th>대표이사명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          {{ model.ceo }}
          <!-- <Input :isDisabled="true" :value.sync="model.ceo" /> -->
        </td>
      </tr>
      <tr>
        <th>사업자번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          {{ model.corporateNum }}
          <!-- <Input :isDisabled="true" :value.sync="model.corporateNum" /> -->
        </td>
      </tr>
      <tr>
        <th>본사 주소<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputAddress
            :zipcode.sync="model.zipcode"
            :address.sync="model.address"
            :address2.sync="model.address2"
            addressTip="* 사업자등록증 본사 주소지 입력"
          />
        </td>
      </tr>

      <!-- <tr>
        <th>신용평가등급</th>
        <td>-</td>
        <th>등록점수</th>
        <td>-</td>
      </tr>
      <tr>
        <th>현금흐름등급</th>
        <td>-</td>
        <th>설립일</th>
        <td>-</td>
      </tr>
      <tr>
        <th>기업형태</th>
        <td>-</td>
        <th>연매출액</th>
        <td>-</td>
      </tr> -->
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import InputAddress from "@/components/common/input/InputAddress";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";

export default {
  name: "SupplyPartnerInfo",
  components: {
    TableViewWithTitle,
    Input,
    InputAddress,
    InputEmail,
    InputPhone,
  },
  props: {
    model: Object,

    contractType: String,
  },
  computed: {
    disabeldPartnerSelect() {
      let ret = false;
      switch (this.contractType) {
        case "estimate":
          ret = true;
          break;
        case "renewal":
          ret = true;
          break;
        default:
          ret = false;
          break;
      }
      return !ret;
    },
  },
  methods: {
    onClickPopPartnerSelect() {
      this.$emit("onClickPopPartnerSelect");
    },
  },
};
</script>
