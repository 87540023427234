<template>
  <Fold title="기타 지불방법" :isFolded="true">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>
            납품 기한<span
              v-if="necessaryValue && necessaryValue.etcDeliveryDate"
              class="ico_purchase ico_star"
              >필수항목</span
            >
          </th>
          <td>
            <DatePicker :date.sync="model.etcDeliveryDate" />
          </td>
          <th>
            계약금액<span
              v-if="necessaryValue && necessaryValue.etcContractPrice"
              class="ico_purchase ico_star"
              >필수항목</span
            >
          </th>
          <td>
            <SelectBoxCurrencyIncludeData
              inputWidth="w282"
              directInputWidth="w80"
              isDirectModeInputWidth="w194"
              placeholderCount="계약금액"
              :inputMaxLength="14"
              :isAutoMoneyType="true"
              :value.sync="contractModel.currencyCode"
              :count.sync="model.etcContractPrice"
            />
          </td>
        </tr>
        <tr>
          <th>
            지급 비율<span
              v-if="necessaryValue && necessaryValue.etcPaymentRate"
              class="ico_purchase ico_star"
              >필수항목</span
            >
          </th>
          <td>
            <div class="group_form">
              <Input class="w90" :value.sync="model.etcPaymentRate" />
              <div class="txt_view_r">%</div>
            </div>
          </td>
          <th>
            지급 금액<span
              v-if="necessaryValue && necessaryValue.etcPayment"
              class="ico_purchase ico_star"
              >필수항목</span
            >
          </th>
          <td>
            <SelectBoxCurrencyIncludeData
              inputWidth="w282"
              directInputWidth="w80"
              isDirectModeInputWidth="w194"
              placeholderCount="지급 금액"
              :inputMaxLength="14"
              :isAutoMoneyType="true"
              :value.sync="contractModel.currencyCode"
              :count.sync="model.etcPayment"
            />
          </td>
        </tr>
        <tr>
          <th>
            하자보증 비율<span
              v-if="necessaryValue && necessaryValue.etcDefactRate"
              class="ico_purchase ico_star"
              >필수항목</span
            >
          </th>
          <td colspan="3">
            <div class="group_form">
              <Input class="w90" :value.sync="model.etcDefactRate" />
              <div class="txt_view_r">%</div>
            </div>
          </td>
        </tr>
      </template>
    </TableView>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import DatePicker from "@/components/common/calendar/DatePicker";
import Input from "@/components/common/input/Input";
import SelectBoxCurrencyIncludeData from "@/components/common/selectBox/SelectBoxCurrencyIncludeData";
import PaymentMethod from "./PaymentMethodModel";
import ContractInfoModel from "./ContractInfoModel";

// import OrderInfoModel from './OrderInfoModel'

export default {
  name: "AddPaymentMethod",
  components: {
    Fold,
    TableView,
    DatePicker,
    Input,
    SelectBoxCurrencyIncludeData,
  },
  props: {
    model: PaymentMethod,
    contractModel: ContractInfoModel,
    necessaryValue: Object,
  },
  data() {
    return {
      //dates: [],
      // currencyCode: 'KRW',
      price: "",
    };
  },
  // props: {
  //   orderCid: String,
  //   model: OrderInfoModel
  // }
};
</script>
