<template>
  <FilterBarBase btnSize="medium" @onClickSearch="onClickSearch">
    <dl>
      <dt>등록일</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            class="w152"
            :dataList="radioData.dataList"
            :value.sync="radioData.selectedId"
          />
          <Input
            class="w452"
            :value.sync="inputCurrentText"
            :isFocusOnMount="true"
            placeholder="검색어를 입력하세요"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Input from "@/components/common/input/Input";

export default {
  name: "EstimateRequestsPopupFilterBar",
  components: {
    FilterBarBase,
    DateRangeQuickSelect,
    SelectBox,
    Input,
  },
  data() {
    return {
      dates: [],
      inputCurrentText: "",
      radioData: {
        name: "OrderAnnoucePopupFilterBarRadio0",
        dataList: [
          { code: "title", desc: "견적서명" },
          { code: "estimateNum", desc: "견적번호" },
          // { code: 'companyName', desc: '입찰 선정 업체' },
          // { code: 'postCid', desc: '계약번호' },
          // { code: 'title', desc: '입찰 공고명' },
          // { code: 'companyName', desc: '입찰 선정 업체' },
          // { code: '2', desc: '구매 담당자' },  // api 명세에 없음.
        ],
        selectedId: "title",
      },
    };
  },
  methods: {
    onClickSearch(e) {
      // 그냥 빈 값이면 전체 조회 가능하게.
      // if( !this.inputCurrentText.trim() ) {
      //   return;
      // }

      const ret = {
        dates: this.dates,
        radioSelectedId: this.radioData.selectedId,
        inputCurrentText: this.inputCurrentText,
      };

      this.$emit("onClickSearch", ret);
    },
  },
};
</script>
