<template>
  <FilterBarBase btnSize="medium" @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox
            class="w152"
            :dataList="radioData.dataList"
            :value.sync="radioData.selectedId"
          />
          <Search
            class="w588"
            placeholder="선택한 검색어를 입력하세요"
            dropBoxMaxHeight="162px"
            :dataList="autoDataList"
            :value.sync="inputCurrentText"
            @setValue="onSetValue"
            @onEnterKey="onClickSearch"
            @onInputKeyword="onInputKeyword"
          />
          <!-- @update:value="onInputUpdate" -->
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import Search from "@/components/common/search/Search";
import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    Radio,
    Search,
    SelectBox,
  },
  mixins: [FilterBarMixin],
  props: {
    autoDataList: Array,
    defaultStatus: String,
  },
  data() {
    return {
      status: this.defaultStatus,
      radioData: {
        name: "announceListRadio0",
        dataList: [
          { code: "companyName", desc: "업체명" },
          { code: "name", desc: "파트너사 담당자" },
          { code: "job", desc: "담당업무" },
          { code: "categoryItem", desc: "취급품목" },
        ],
        selectedId: "companyName",
      },
      inputData: {
        placeholder: "선택한 검색어를 입력하세요.",
      },
      inputCurrentText: "",
    };
  },
  methods: {
    onSetValue(item) {
      this.inputCurrentText = item;
    },
    onClickSearch() {
      const ret = {
        radioSelectedId: this.radioData.selectedId,
        inputCurrentText: this.inputCurrentText,
      };
      this.$emit("onClickSearch", ret);
    },
    onInputKeyword(value) {
      const obj = {
        radioSelectedId: this.radioData.selectedId,
        inputCurrentText: value,
      };
      this.$emit("onAutoComplete", obj);
    },
    // onInputUpdate(){
    //   const ret = {
    //     radioSelectedId: this.radioData.selectedId,
    //     inputCurrentText: this.inputCurrentText,
    //   }
    //   this.$emit( 'onAutoComplete', ret );
    // }
    onClickReset() {
      this.radioData.selectedId = "companyName";
      this.inputCurrentText = "";
    },
  },
};
</script>
