<template>
  <TableViewWithTitle title="계약 설정">
    <template v-slot:body>
      <tr>
        <th>구매요청서 정보 <span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <template v-if="!isModifyMode">
            <div class="group_form">
              <button
                type="button"
                class="btn_fourthly btn_small"
                @click="$emit('onClickPurchaseRequestesSearch')"
              >
                구매요청 정보 불러오기
              </button>
            </div>
            <ConnectEditOnlyOne
              :dataList="purchaseWfDataList"
              :isDisabled="purchaseWfDataList.aprvlStatus == '-'"
              @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
              @onClickRemove="$emit('onClickRemovePurchaseReqeust')"
            />
          </template>
          <template v-else>
            <ConnectViewOnlyOne
              :dataList="purchaseWfDataList"
              @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
            />
          </template>
        </td>
      </tr>
      <tr v-if="type == 'estimate'">
        <th>견적서 정보</th>
        <td colspan="3" style="height: 73px">
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="$emit('onClickEstimateRequestesSearch')"
            >
              견적서 정보 불러오기
            </button>
          </div>
          <ConnectEditOnlyOne
            :dataList="estimateWfDataList"
            @onClickConnect="(item) => $emit('onClickPopEstimatePrint', item)"
            @onClickRemove="$emit('onClickRemoveEstimateReqeust')"
          />
        </td>
      </tr>
      <tr>
        <th>프로젝트코드</th>
        <td>
          <div class="group_form">
            <Input
              placeholder="프로젝트 코드"
              :isDisabled="true"
              :value.sync="contractInfoModel.projectCode"
            />
          </div>
        </td>
        <th>프로젝트명</th>
        <td>
          <div class="group_form">
            <Input
              placeholder="프로젝트명"
              :isDisabled="true"
              :value.sync="contractInfoModel.projectName"
            />
          </div>
        </td>
      </tr>
      <tr>
        <th>계약명 <span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <Input placeholder="계약명" :value.sync="contractInfoModel.description" />
          </div>
        </td>
      </tr>
      <tr>
        <th>계약서 템플릿 <span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="onClickTemplateSelectPopup"
            >
              템플릿 선택
            </button>
            <Input
              class="w282"
              :isDisabled="true"
              placeholder="계약서 템플릿을 선택해주세요"
              :value.sync="templateNameSync"
            />
          </div>
        </td>
        <th>
          계약기간
          <span class="ico_purchase ico_star">필수항목</span>
        </th>
        <td>
          <DateRange :dates.sync="contractInfoModel.dates" />
        </td>
      </tr>
      <tr>
        <th>계약 체결일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DatePicker :date.sync="contractAddInfoModel.signDate" />
          <!-- :date.sync="model.etcDeliveryDate" -->
        </td>
        <th>납품기한<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DatePicker :date.sync="paymentMethodModel.etcDeliveryDate" />
        </td>
      </tr>

      <!-- <tr>
        <th>수행도평가 진행여부<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Radio
            name="performanceUseYn"
            :dataList="performanceUseDataList"
            :selectedId.sync="performanceUseYnSync"
          />
        </td>
      </tr> -->

      <!-- 20.11.24 별도템플릿 이전 테이블 
        <tr>
        <th>전자서명 사용여부<span class="ico_purchase ico_star">필수항목</span></th>
        <td :colspan="signUseYn == 'N' ? 3 : 1">
          <div class="group_form">
            <Radio
              name="signUseYn"
              :dataList="signUseDataList"
              :selectedId.sync="signUseYnSync" />
          </div>
        </td>
        <th v-if="signUseYn == 'Y'">계약서 템플릿<span v-if="signUseYn == 'Y'" class="ico_purchase ico_star">필수항목</span></th>
        <td v-if="signUseYn == 'Y'">
          <div class="group_form">
            <button type="button" class="btn_fourthly btn_small" @click="onClickTemplateSelectPopup">템플릿 선택</button>
            <Input
              class="w282"
              :isDisabled="true"
              placeholder="계약서 템플릿을 선택해주세요"
              :value.sync="templateNameSync"/>
          </div>
        </td>
      </tr>
      <tr>
        <th>수행도평가 진행여부<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Radio
            name="performanceUseYn"
            :dataList="performanceUseDataList"
            :selectedId.sync="performanceUseYnSync" />
        </td>
      </tr> -->
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";
import ConnectEditOnlyOne from "@/components/common/connect/ConnectEditOnlyOne";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";

import DateRange from "@/components/common/calendar/DateRange";
import DatePicker from "@/components/common/calendar/DatePicker";

export default {
  name: "ContractSetting",
  components: {
    TableViewWithTitle,
    Radio,
    Input,
    DateRange,
    DatePicker,
    ConnectEditOnlyOne,
    ConnectViewOnlyOne,
  },
  props: {
    isModifyMode: Boolean,
    contractYn: String,
    purchaseWfDataList: Object,
    contractInfoModel: Object,
    contractAddInfoModel: Object,
    paymentMethodModel: Object,

    computedManagerObj: Object, //구매담당자 정보
    contractRequester: Object, //구매요청자 정보
    signUseYn: String,
    performanceUseYn: String,
    templateName: String,
    normalYn: String,

    estimateWfDataList: Object || String,
    type: String,
  },
  data() {
    return {
      contractYnDataList: [
        { id: "Y", text: "Y" },
        { id: "N", text: "N" },
      ],

      signUseDataList: [
        { id: "Y", text: "Y" },
        { id: "N", text: "N" },
      ],
      performanceUseDataList: [
        { id: "Y", text: "Y" },
        { id: "N", text: "N" },
      ],
      templateFormatList: [
        { id: "Y", text: "일반" },
        { id: "N", text: "별도" },
      ],
    };
  },
  computed: {
    computedContractRequester() {
      const { name, loginId } = this.contractRequester;
      let ret = "";
      if (name && loginId) {
        ret = `${name} (${loginId})`;
      } else {
        ret = "";
      }
      return ret;
    },

    signUseYnSync: {
      get() {
        return this.signUseYn;
      },
      set(value) {
        this.$emit("update:signUseYn", value);
      },
    },
    performanceUseYnSync: {
      get() {
        return this.performanceUseYn;
      },
      set(value) {
        this.$emit("update:performanceUseYn", value);
      },
    },
    templateNameSync: {
      get() {
        return this.templateName;
      },
      set(value) {
        this.$emit("update:templateName", value);
      },
    },
    templateSync: {
      get() {
        return this.normalYn;
      },
      set(value) {
        this.$emit("update:normalYn", value);
      },
    },
  },
  methods: {
    onClickTemplateSelectPopup() {
      this.$emit("onClickTemplateSelectPopup");
    },
    onClickEtcTemplatePop() {
      this.$emit("onClickEtcTemplatePop");
    },
    onClickPopLdap(target) {
      this.$emit("onClickPopLdap", target);
    },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & .tf_comm {
  }
}
</style>
