<template>
  <div class="group_form">
    <SelectBox
      class="w208"
      :dataList="reduceListByType"
      :value.sync="selectedValue"
      :isDisabled="isDisabledSelectBox"
      :placeholder="placeholder"
      @update:value="onChangeSelect"
    />
    <Input
      v-if="isDirectMode"
      class="w164"
      :value.sync="valueSync"
      :isDisabled="isDisabledSelectBox"
      placeholder="절감 기준"
      :maxLength="30"
    />
    <slot />
  </div>
</template>

<script>
import SelectBox from "@/components/common/selectBox/SelectBox";
import Input from "@/components/common/input/Input";

import LocalStorageManager from "@/LocalStorageManager";

import { mapState } from "vuex";

export default {
  //절감기준
  name: "SelectBoxReduceStandard",
  components: {
    SelectBox,
    Input,
  },
  props: {
    value: String,
    placeholder: String,
    isDisabledSelectBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  mounted() {
    this.setValueByProps();
  },
  computed: {
    ...mapState({
      reduceList: (state) => state.contract.reduceList,
    }),
    isDirectMode() {
      return this.selectedValue === "직접입력";
    },
    reduceSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    valueSync: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },

    reduceListByType() {
      return this.reduceList;
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.setValueByProps();
    },
  },
  methods: {
    onChangeSelect(value) {
      if (this.isDirectMode) {
        this.$emit("update:value", "");
      } else {
        this.$emit("update:value", value);
      }
    },
    setValueByProps() {
      var toSelectedValue;
      switch (this.value) {
        case "C":
        case "E":
        case "B":
        case null:
          toSelectedValue = this.value;
          break;
        default:
          toSelectedValue = "직접입력";
      }

      if (this.selectedValue !== toSelectedValue) {
        this.selectedValue = toSelectedValue;
      }
    },
  },
};
</script>
