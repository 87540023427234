import LocalStorageManager from "@/LocalStorageManager";
//유저데이터 꼽아 넣고

class ManagerInfoModel {
  constructor() {
    // this.objRaw = null;
    this.userData = LocalStorageManager.shared.getUserData();
    this.managerId = this.userData.username;
    this.managerName = this.userData.name;
    this.managerNum = this.userData.managerNum;
    this.managerEmail = this.userData.email;
    this.managerTel = this.userData.tel;
    this.contractManagerList = [];
    this.bManager = {
      code: "B",
      email: "",
      loginId: "",
      name: "",
      tel: "",
      personNum: "",
    };
    this.iManager = {
      code: "I",
      email: "",
      loginId: "",
      name: "",
      tel: "",
      personNum: "",
    };
  }

  setData(obj) {
    const { managerId, managerName, managerNum, managerEmail, managerTel, contractManagerList } =
      obj;
    this.managerId = managerId;
    this.managerName = managerName;
    this.managerNum = managerNum;
    this.managerEmail = managerEmail;
    this.managerTel = managerTel;
    this.contractManagerList = contractManagerList;
    this.setManager(contractManagerList);
  }
  setManager(arr) {
    const bManager = arr.find((item) => item.code === "B");
    this.bManager = bManager;
    const iManager = arr.find((item) => item.code === "I");
    this.iManager = iManager;
  }

  setBManager(obj) {
    const { requesterId, requesterName, requesterTel, requesterEmail, requesterPersonNum } = obj;
    const bManager = {
      code: "B",
      email: requesterEmail,
      loginId: requesterId,
      name: requesterName,
      tel: requesterTel,
      personNum: requesterPersonNum || "",
    };
    this.contractManagerList.push(bManager);
    this.bManager = bManager;
  }
  setIManager(obj) {
    const { requesterId, requesterName, requesterTel, requesterEmail, requesterPersonNum } = obj;
    const iManager = {
      code: "I",
      email: requesterEmail,
      loginId: requesterId,
      name: requesterName,
      tel: requesterTel,
      personNum: requesterPersonNum,
    };
    this.contractManagerList.push(iManager);
    this.iManager = iManager;
  }

  getData() {
    const { managerId, managerName, managerNum, managerEmail, managerTel, contractManagerList } =
      this;
    let obj = {
      managerId,
      managerName,
      managerNum,
      managerEmail,
      managerTel,
      contractManagerList,
    };

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default ManagerInfoModel;
