import ApiService from "@/services/ApiService";

import { makeQueryStringByObject } from "@/utils/urlUtils";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

const EstimateRequestsPopupMixin = {
  props: {
    estimateWfDataList: undefined,
    isMultiMode: {
      type: Boolean,
      default: false,
    },
    type: String,
  },
  data() {
    return {
      dataList: [],
      selectedItem: {}, // 단일선택 시 선택 값
      selectedDataList: [], // 다중선택 시 선택 값 리스트
      filtersParams: {},
    };
  },
  beforeMount() {
    //this.getDataInit(params);
  },
  mounted() {
    if (this.isMultiMode) {
      if (!this.estimateWfDataList || this.estimateWfDataList.length == 0) return;
      this.selectedDataList = this.estimateWfDataList.map((item) => {
        return {
          estimateNum: item.estimateNum,
          requesterId: item.requester,
          requesterName: item.requesterName,
          title: item.title,
        };
      });
    } else {
      if (!this.estimateWfDataList) return;

      const {
        estimateNum,
        assetTypeName,
        itemTitle,
        name,
        price,
        regDate,
        requestCid,
        requester,
        requesterId,
        requesterEmpNo,
        requesterName,
        title,
        type,
        contents,
      } = this.estimateWfDataList;
      const obj = {
        estimateNum,
        assetTypeName,
        itemTitle,
        name,
        price,
        regDate,
        requestCid,
        requester,
        requesterId,
        regEmpNo: requesterEmpNo,
        requesterName,
        title,
        type,
        contents,
      };
      this.selectedItem = obj;
    }
  },
  methods: {
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickSearch(objData) {
      let params = this.getFilterbarParams(objData);
      this.filtersParams = this.getFilterbarParams(objData);
      params.page = "1";
      params.size = this.size;

      this.getData(params);

      //this.search(path);
    },
    async getDataInit(params) {
      this.getData(params);

      // this.dataList = result.data;
    },
    async getData(params) {
      await this.$store.dispatch(PROGRESS_START_ACTION);
      // this.selectedItem = {};
      const urlRest = makeQueryStringByObject(params);
      const url = this.API_PATH + urlRest;

      const result = await ApiService.shared.getData(url);
      await this.$store.dispatch(PROGRESS_END_ACTION);

      const { data, total } = result;
      if (!result.data) {
        this.dataList = [];
        return;
      }
      if (data) {
        this.dataList = data;

        this.currentPageIndex = parseInt(result.page) - 1;
        this.totalPageCount = parseInt(total);
      } else {
        this.dataList = [];
        this.totalPageCount = 0;
      }
    },
    checkUrlQuery() {
      // const objStatus = {
      //   status: this.status,
      // };
      const objPaging = this.checkQueryPaging();

      return Object.assign({}, objPaging); //objStatus,
    },
    checkQueryPaging() {
      const { page, size } = this;

      const ret = {
        page: page || "1",
        // size: size || this.defaultRowCount,
        size: size || this.size || this.defaultRowCount,
      };

      this.currentPageIndex = Number.parseInt(ret.page) - 1;
      this.size = Number.parseInt(ret.size);

      return ret;
    },
    onChangePage(nPageIndex) {
      // const objStatus = {
      //   status: this.status,
      // };
      this.currentPageIndex = nPageIndex;
      this.page = nPageIndex + 1;
      let query = Object.assign({}, this.filtersParams, {
        page: nPageIndex + 1,
        size: this.size,
      }); //objStatus,

      this.getData(query);
    },

    onClickSelect() {
      let result = undefined;
      if (this.isMultiMode) {
        result = this.selectedDataList;
      } else {
        result = this.selectedItem;
      }

      this.$emit("update:estimateWfDataList", result);
      this.$emit("setEstimateContract", result);
      this.$emit("onClickClose");
    },
  },
};
export default EstimateRequestsPopupMixin;
