<template>
  <Fold title="추가 정보">
    <TableViewWithTitle>
      <template v-slot:body>
        <tr>
          <th>설치(수행) 장소</th>
          <td>
            <Input :value.sync="supplyInfoModel.delivery" />
          </td>
          <th>공급 목적물</th>
          <td>
            <Input :value.sync="supplyInfoModel.why" />
          </td>
        </tr>
        <tr>
          <th>
            계약서 첨부파일
            <span v-if="offlineYn" class="ico_purchase ico_star">필수항목</span>
          </th>
          <td>
            <FileWrite
              :canMultiple="true"
              :dataList.sync="attachFile.contractFile"
              @fileUploaded="attachFileContractFileUploaded"
              @fileRemoved="attachFileContractFileRemoved"
            />
          </td>
          <th>발주서 첨부파일</th>
          <td>
            <FileWrite
              :canMultiple="true"
              :dataList.sync="attachFile.purchaseOrderFile"
              @fileUploaded="attachFilePurchaseOrderFileUploaded"
              @fileRemoved="attachFilePurchaseOrderFileRemoved"
            />
          </td>
        </tr>
        <tr>
          <th>OffLine 계약</th>
          <td colspan="3">
            <CheckboxOnlyOne :isSelected.sync="offlineYnSync" :text="'OffLine 계약'" />
          </td>
        </tr>
        <tr>
          <th>특기사항</th>
          <td colspan="3">
            <Textarea
              :value.sync="supplyInfoModel.specialAgreement"
              :maxLength="200"
              :isCount="true"
            />
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import FileWrite from "@/components/common/file/FileWrite";
import Textarea from "@/components/common/textarea/Textarea";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne.vue";

export default {
  name: "AddInfo",
  components: {
    Fold,
    TableViewWithTitle,
    Input,
    FileWrite,
    Textarea,
    CheckboxOnlyOne,
  },
  props: {
    supplyInfoModel: Object,
    offlineYn: Boolean,
    attachFile: Object,
  },
  computed: {
    offlineYnSync: {
      get() {
        return this.offlineYn;
      },
      set(value) {
        this.$emit("update:offlineYn", value);
      },
    },
  },
  methods: {
    attachFileContractFileUploaded(files) {
      this.attachFile.contractFile = this.attachFile.contractFile.concat(files);
    },
    attachFileContractFileRemoved(itemToRemove) {
      this.attachFile.contractFile = this.attachFile.contractFile.filter(
        (item) => item !== itemToRemove,
      );
    },

    attachFilePurchaseOrderFileUploaded(files) {
      this.attachFile.purchaseOrderFile = this.attachFile.purchaseOrderFile.concat(files);
    },
    attachFilePurchaseOrderFileRemoved(itemToRemove) {
      this.attachFile.purchaseOrderFile = this.attachFile.purchaseOrderFile.filter(
        (item) => item !== itemToRemove,
      );
    },
  },
};
</script>
