import SupplyInfoItemModel from "./SupplyInfoItemModel";
import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

class SupplyInfoModel {
  constructor() {
    this.objRaw = {};
    this.orderCid = "";
    this.orderGoodsList = [];
    this.orderInspectedGoodsList = [];

    this.estimatePrice = ""; //제안단계 견적가

    this.totalSavingAmount = "0";
    this.totalContractAmount = "0";
    this.totalVat = "0";
    this.delivery = ""; //설치수행장소
    this.why = ""; //왜??
    this.specialAgreement = ""; //특이사항
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    const { orderGoodsList, orderInspectedGoodsList, ...rest } = obj;

    Object.entries(rest).forEach(([key, value]) => {
      if (!_.isObject(value)) {
        if (!Object.prototype.hasOwnProperty.call(this, key)) {
          console.log(`%c model[${key}] empty`, "color:red");
        }
        this[key] = value;
      }
    });
    if (orderGoodsList?.length > 0) {
      orderGoodsList.map((item) => {
        const itemModel = new SupplyInfoItemModel();
        itemModel.setData(item);
        this.orderGoodsList.push(itemModel);
      });
    }
    if (orderInspectedGoodsList) {
      this.orderInspectedGoodsList = orderInspectedGoodsList;
    }
  }

  getData() {
    const {
      orderCid,
      orderGoodsList,
      estimatePrice,
      totalSavingAmount,
      totalContractAmount,
      totalVat,
      delivery,
      why,
      specialAgreement,
      orderInspectedGoodsList,
    } = this;
    const orderGoodsAddInfo = {
      estimatePrice: getMoneyToNumber(estimatePrice),
      totalSavingAmount,
      totalContractAmount,
      totalVat,
      delivery,
      why,
      specialAgreement,
    };
    const obj = {
      orderGoodsList: orderGoodsList.map((item) => {
        return item.getData();
      }),
      orderGoodsAddInfo,
      orderInspectedGoodsList: orderInspectedGoodsList.length > 0 ? orderInspectedGoodsList : null,
    };

    return obj;
  }
  addItem() {
    const itemModel = new SupplyInfoItemModel();
    this.orderGoodsList.push(itemModel);
  }

  setOrderGoodsList(arr) {
    const orderGoodsList = arr.map((item) => {
      const itemModel = new SupplyInfoItemModel();
      itemModel.setData(item);
      return itemModel;
    });
    this.orderGoodsList = _.cloneDeep(orderGoodsList);
  }

  getAlertMessage() {
    const isMarketPrice = this.orderGoodsList.some((item) => !item.marketPrice);
    if (isMarketPrice) return "소비자가를 입력해주세요.";
    const isContractPrice = this.orderGoodsList.some((item) => !item.contractPrice);
    if (isContractPrice) return "계약금액을 입력해주세요.";
    // if (!this.amount) return '납품 내역의 수량을 확인해주세요.';
    // if (!this.unitPrice) return '납품 내역의 단가를 확인해주세요.';
    // if (!this.price) return '납품 내역의 금액을 확인해주세요.';

    //if (!this.cmdbAssetInfo) return '납품 내역의 품명을 확인해주세요.';
    //if (!this.projectCode) return '납품 내역의 프로젝트코드를 확인해주세요.';
    //if( this.vat === 'vatChoice' ) return '납품 내역의 부가세를 확인해주세요.';

    return null;
  }
}

export default SupplyInfoModel;
