<template>
  <TableViewWithTitle title="협력사 담당자 정보">
    <template v-slot:body>
      <tr>
        <th>협력사 담당자<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input class="w380" :value.sync="model.name" />
        </td>
      </tr>
      <tr>
        <th>휴대전화<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputPhone
            :isDisabled="isDisabledPartnerInfo"
            :useMobile="true"
            :useArea="true"
            :phone0.sync="model.mobile.phone0"
            :phone1.sync="model.mobile.phone1"
            :phone2.sync="model.mobile.phone2"
          />
        </td>
        <th>이메일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputEmail
            :isDisabled="isDisabledPartnerInfo"
            :emailName.sync="model.email.name"
            :emailDomain.sync="model.email.domain"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";

// import SupplyPartnerManagerInfoModel from './SupplyPartnerManagerInfoModel';

export default {
  name: "SupplyPartnerManagerInfoInput",
  components: {
    TableViewWithTitle,
    Input,
    InputEmail,
    InputPhone,
  },
  props: {
    model: Object,
    isDisabledPartnerInfo: Boolean,
  },
};
</script>
