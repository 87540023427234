import { getMoneyToNumber } from "@/utils/stringUtils";

class PaymentMethodRateLineModel {
  constructor() {
    this.objRaw = {};
    this.billingType = "";
    this.billingName = "";
    this.rate = "";
    this.amount = "";
    this.orderNum = "";
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    Object.entries(obj).forEach(([key, value]) => {
      if (!_.isObject(value)) {
        if (!Object.prototype.hasOwnProperty.call(this, key)) {
          console.log(`%c model[${key}] empty`, "color:red");
        }
        this[key] = value;
      }
    });
    // obj value가 object,array 면 따로 예외처리한다
  }

  getData() {
    const { billingType, billingName, rate, amount, orderNum } = this;
    const obj = {
      billingType,
      billingName,
      rate,
      orderNum,
      amount: getMoneyToNumber(amount),
    };
    return obj;
  }

  getAlertMessage() {
    if (!this.rate) return "지급비율을 확인해주세요.";
    return null;
  }
}

export default PaymentMethodRateLineModel;
