<template>
  <div class="area_view">
    <template v-if="supplyInfoModel.orderInspectedGoodsList.length > 0">
      <TableHead :title="`기검수품목`"> </TableHead>
      <Board
        :disableHover="true"
        :dataList="supplyInfoModel.orderInspectedGoodsList"
        :showPagination="false"
      >
        <template v-slot:colgroup>
          <!-- No -->
          <col style="width: 40px" />
          <!-- 품명 -->
          <col />
          <!-- 품목코드 -->
          <col style="width: 110px" />
          <!-- 자산코드 -->
          <col style="width: 110px" />
          <!-- 단위 -->
          <col style="width: 80px" />
          <!-- 수량 -->
          <col style="width: 80px" />
          <!-- 예산금액 -->
          <col style="width: 120px" />
          <!-- 소비자가 -->
          <col style="width: 120px" />
          <!-- 계약금액 -->
          <col style="width: 120px" />
          <!-- 할인율(%) -->
          <col style="width: 80px" />
          <!-- 부가세 -->
          <col style="width: 100px" />
          <!-- 절감액 -->
          <col style="width: 100px" />
          <!-- 절감율(%) -->
          <col style="width: 80px" />
          <!-- 품목상세 -->
          <col style="width: 160px" />
          <!-- 추가/삭제 -->
          <col style="width: 80px" />
        </template>
        <template v-slot:tr>
          <th scope="col">No.</th>
          <th scope="col">품명</th>
          <th scope="col">품목코드</th>
          <th scope="col">자산코드</th>
          <th scope="col">단위</th>
          <th scope="col">수량</th>
          <th scope="col">예산금액</th>
          <th scope="col">소비자가</th>
          <th scope="col">계약금액</th>
          <th scope="col">할인율(%)</th>
          <th scope="col">부가세</th>
          <th scope="col">절감액</th>
          <th scope="col">절감율(%)</th>
          <th scope="col">품목상세</th>
          <th></th>
        </template>
        <template v-slot:rows>
          <SupplyInfoLineInspectedRenewal
            v-for="(item, index) in supplyInfoModel.orderInspectedGoodsList"
            :key="index"
            :item="item"
            :index="index"
            :assetType="assetType"
          />
        </template>
        <template v-slot:emptyList>
          <tr>
            <td colspan="15">구매요청서를 연결해주세요.</td>
          </tr>
        </template>
      </Board>
    </template>
    <template>
      <TableHead :title="`${assetTypeName ? assetTypeName : ''} 품목 상세`"> </TableHead>
      <Board
        :disableHover="true"
        :dataList="supplyInfoModel.orderGoodsList"
        :showPagination="false"
      >
        <template v-slot:colgroup>
          <!-- No -->
          <col style="width: 40px" />
          <!-- 품명 -->
          <col />
          <!-- 품목코드 -->
          <col style="width: 110px" />
          <!-- 자산코드 -->
          <col style="width: 110px" />
          <!-- 단위 -->
          <col style="width: 80px" />
          <!-- 수량 -->
          <col style="width: 80px" />
          <!-- 예산금액 -->
          <col style="width: 120px" />
          <!-- 소비자가 -->
          <col style="width: 120px" />
          <!-- 계약금액 -->
          <col style="width: 120px" />
          <!-- 할인율(%) -->
          <col style="width: 80px" />
          <!-- 부가세 -->
          <col style="width: 100px" />
          <!-- 절감액 -->
          <col style="width: 100px" />
          <!-- 절감율(%) -->
          <col style="width: 80px" />
          <!-- 품목상세 -->
          <col style="width: 160px" />
          <!-- 추가/삭제 -->
          <col style="width: 80px" />
        </template>
        <template v-slot:tr>
          <th scope="col">No.</th>
          <th scope="col">품명</th>
          <th scope="col">품목코드</th>
          <th scope="col">자산코드</th>
          <th scope="col">단위</th>
          <th scope="col">수량</th>
          <th scope="col">예산금액</th>
          <th scope="col">소비자가<span class="ico_purchase ico_star">필수항목</span></th>
          <th scope="col">계약금액<span class="ico_purchase ico_star">필수항목</span></th>
          <th scope="col">할인율(%)</th>
          <th scope="col">부가세</th>
          <th scope="col">절감액</th>
          <th scope="col">절감율(%)</th>
          <th scope="col">품목상세</th>
          <th>
            <button type="button" class="btn_secondary btn_small" @click="onClickAdd">추가</button>
          </th>
        </template>
        <template v-slot:rows>
          <SupplyInfoLineRenewal
            v-for="(item, index) in supplyInfoModel.orderGoodsList"
            :key="index"
            :item="item"
            :index="index"
            :assetType="assetType"
            @onClickGoodsNamePopup="(index) => $emit('onClickGoodsNamePopup', index)"
            @changePrice="changePrice"
            @onClickRemove="onClickRemove"
          />
        </template>
        <template v-slot:emptyList>
          <tr>
            <td colspan="15">구매요청서를 연결해주세요.</td>
          </tr>
        </template>
      </Board>
      <SupplyFinalPrice
        :type="type"
        :attachFile="attachFile"
        :supplyInfoModel="supplyInfoModel"
        :totalInspectAmount="totalInspectAmount"
      />
    </template>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import SupplyInfoLineRenewal from "./SupplyInfoLineRenewal";
import SupplyInfoLineInspectedRenewal from "./SupplyInfoLineInspectedRenewal";

import SelectBoxCurrencyNoInputCount from "@/components/common/selectBox/SelectBoxCurrencyNoInputCount";
import SelectBox from "@/components/common/selectBox/SelectBox";

import SupplyFinalPrice from "./SupplyFinalPrice";

import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

import SupplyInfoItemModel from "./SupplyInfoItemModel";
//import SupplyFinalPriceModel from './SupplyFinalPriceModel';

export default {
  name: "SupplyInfoRenewal",
  components: {
    TableHead,
    Board,
    SupplyInfoLineRenewal,
    SupplyInfoLineInspectedRenewal,
    SelectBoxCurrencyNoInputCount,
    SelectBox,
    SupplyFinalPrice,
  },
  props: {
    type: String,
    assetType: String,
    assetTypeName: String,
    totalInspectAmount: Number,
    supplyInfoModel: Object,

    attachFile: Object,
  },
  data() {
    return {
      //supplyFinalPriceModel: new SupplyFinalPriceModel(),
    };
  },
  computed: {},
  watch: {},
  created() {
    this.setFinalData();
  },
  methods: {
    changePrice(item) {
      this.setFinalData();
    },
    setFinalData() {
      const { orderGoodsList } = this.supplyInfoModel;

      const totalSavingAmount = orderGoodsList.reduce((acc, item, index) => {
        const { savingAmount } = item.getData();
        let ret = acc;
        ret = acc + savingAmount;
        return ret;
      }, 0);
      const totalContractAmount = orderGoodsList.reduce((acc, item, index) => {
        const { contractPrice } = item.getData();
        let ret = acc;
        ret = acc + contractPrice;
        return ret;
      }, 0);
      const totalVat = orderGoodsList.reduce((acc, item, index) => {
        const { vat } = item.getData();
        let ret = Number(acc);
        ret = Number(acc) + Number(vat);
        return ret;
      }, 0);
      const finalData = {
        totalSavingAmount: totalSavingAmount,
        totalContractAmount: totalContractAmount,
        totalVat: totalVat,
      };

      this.supplyInfoModel.setData(finalData);
    },

    onClickRemove(removeIndex) {
      const filterList = this.supplyInfoModel.orderGoodsList.filter(
        (item, index) => index !== removeIndex,
      );
      this.supplyInfoModel.orderGoodsList = filterList;
      this.setFinalData();
    },
    onClickAdd() {
      this.supplyInfoModel.addItem();
    },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
