import PhoneModel from "@/models/PhoneModel";
import EmailModel from "@/models/EmailModel";

export default class SupplyPartnerInfoModel {
  constructor() {
    this.objRaw = {};

    this.userNum = "";
    this.companyName = "";
    this.ceo = "";
    this.corporateNum = "";
    this.name = "";

    this.zipcode = "";
    this.address = "";
    this.address2 = "";

    this.email = new EmailModel();
    this.tel = new PhoneModel("010");
    this.mobile = new PhoneModel("010");
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      userNum,
      companyName,
      ceo,
      corporateNum,
      zipcode,
      address,
      address2,
      name,
      tel,
      mobile,
      email,
      personNum,
    } = obj;
    this.userNum = userNum;
    this.companyName = companyName || "";
    this.ceo = ceo || "";
    this.corporateNum = corporateNum || "";
    this.name = name || "";
    this.personNum = personNum || "";
    this.zipcode = zipcode || "";
    this.address = address || "";
    this.address2 = address2 || "";

    if (tel) {
      this.tel.setDataByString(tel);
    } else {
      this.tel = new PhoneModel("010");
    }

    if (mobile) {
      this.mobile.setDataByString(mobile);
    } else {
      this.mobile = new PhoneModel("010");
    }

    if (email) {
      this.email.setDataByString(email);
    } else {
      this.email = new EmailModel();
    }
  }

  getData() {
    let obj = {
      userNum: this.userNum,
      companyName: this.companyName,
      ceo: this.ceo,
      corporateNum: this.corporateNum,
      name: this.name,
      zipcode: this.zipcode,
      address: this.address,
      address2: this.address2,
      email: this.email.getIsValid() ? this.email.getTextFull() : "",
      tel: this.tel.getIsValid() ? this.tel.getTextFull() : "",
      mobile: this.mobile.getIsValid() ? this.mobile.getTextFull() : "",
    };

    return Object.assign({}, this.objRaw, obj);
  }

  getAlertMessage() {
    if (!this.companyName) return "파트너사 업체명을 확인해주세요.";
    if (!this.ceo) return "파트너사 대표이사명을 확인해주세요.";
    if (!this.corporateNum) return "파트너사 사업자등록번호를 확인해주세요.";
    if (!this.name) return "파트너사 담당자를 확인해주세요.";
    if (!this.mobile.phone0) return "휴대전화를 확인해주세요.";
    if (!this.mobile.phone1) return "휴대전화를 확인해주세요.";
    if (!this.mobile.phone2) return "휴대전화를 확인해주세요.";
    if (!this.zipcode.trim()) return "파트너사 주소를 확인해주세요.";
    if (!this.address.trim()) return "파트너사 주소를 확인해주세요.";
    //if (!this.address2.trim()) return '파트너사 주소를 확인해주세요.';
    if (!this.email.getIsValid()) return "파트너사 이메일을 확인해주세요.";
    // if( !this.tel.getIsValid() )  return '파트너사 전화번호를 확인해주세요.';

    return null;
  }
}
