<template>
  <tr :class="{ tr_select: isSelected }">
    <td>
      <RadioItem
        v-if="!isMultiMode"
        :id="{ estimateNum: rowData.estimateNum }"
        name="EstimateRequestsSelete"
        :selectedId.sync="selectedItemSync"
      />
      <CheckboxItem
        v-else
        :id="{ estimateNum: rowData.estimateNum }"
        :checkedNames.sync="selectedDataListSync"
      />
    </td>
    <td>{{ rowData.regDate | dateStringFormat }}</td>
    <td>{{ rowData.estimateNum }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.title">
      {{ rowData.title }}
    </td>
    <!-- <td>{{ `${rowData.requesterId}(${rowData.requesterName})` }}</td> -->
    <td>
      {{ `${rowData.companyName}` }}
    </td>
    <td>
      {{ `${rowData.name}` }}
    </td>
  </tr>
</template>

<script>
import RadioItem from "@/components/common/radio/RadioItem";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getCountWithCurrency } from "@/utils/spUtils";

import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  name: "EstimateRequestsPopupLine",
  components: {
    RadioItem,
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
    isMultiMode: Boolean,
    selectedItem: Object,
    selectedDataList: Array,
  },
  computed: {
    idValue() {
      let obj = {};
      obj.accountId = this.rowData.accountId;
      obj.assetTypeName = this.rowData.assetTypeName;
      obj.itemTitle = this.rowData.itemTitle;
      obj.name = this.rowData.name;
      obj.price = this.rowData.price;
      obj.regDate = this.rowData.regDate;
      obj.estimateNum = this.rowData.estimateNum;
      obj.requester = this.rowData.requester;
      obj.requesterEmpNo = this.rowData.requesterEmpNo;
      obj.requesterId = this.rowData.requesterId || this.rowData.accountId;
      obj.requesterName = this.rowData.requesterName || this.rowData.name;
      obj.title = this.rowData.title;
      obj.contents = this.rowData.contents;
      return obj;
    },
    isSelected() {
      if (!this.isMultiMode) {
        return this.selectedItem && this.selectedItem.estimateNum === this.rowData.estimateNum;
      } else {
        const ret = this.selectedDataList.find(
          (item) => item.estimateNum === this.rowData.estimateNum,
        );

        return !!ret;
      }
    },
    selectedItemSync: {
      get() {
        return { estimateNum: this.selectedItem.estimateNum };
      },
      set(value) {
        if (value.estimateNum == this.rowData.estimateNum) {
          value = this.idValue;
        }
        this.$emit("update:selectedItem", value);
      },
    },

    selectedDataListSync: {
      get() {
        const result = this.selectedDataList.map((item) => {
          return { estimateNum: item.estimateNum };
        });
        return result;
      },
      set(value) {
        let addValue = Array.from(this.selectedDataList);
        addValue.push(this.idValue);
        let removeValue = Array.from(this.selectedDataList);
        removeValue = removeValue.filter((item) => item.estimateNum != this.rowData.estimateNum);
        const result = value.find((item) => item.estimateNum == this.rowData.estimateNum)
          ? addValue
          : removeValue;
        this.$emit("update:selectedDataList", result);
      },
    },
    draftingPrice() {
      return addCommaForMoney(this.rowData.draftingPrice);
    },
  },
  // methods: {
  //   onChange( ) {
  //     this.$emit( 'onClickRow', this.rowData );
  //   }
  // }
};
</script>
