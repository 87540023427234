import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";

class SupplyInfoItemModel {
  constructor() {
    this.objRaw = {};

    this.itemNum = "";
    this.itemName = ""; // 품명
    this.itemCode = ""; //품명코드
    this.assetsCode = ""; //자산코드
    this.displayCode = ""; //화면출력코드
    this.quantity = ""; // 수량
    this.price = ""; // 가격
    this.totalPrice = ""; //총합
    this.marketPrice = "0"; // 소비자가
    this.contractPrice = ""; //계약금액
    this.discountRate = ""; //할인율
    this.vat = "0"; //부가세
    this.savingAmount = ""; //절감액
    this.savingsRate = ""; //절감율
    this.projectCode = ""; // 프로젝트 코드
    this.projectName = ""; // 프로젝트 네임
    this.unitType = ""; //단위
    this.unitValue = ""; //단위
    this.standard = ""; // 규격
    this.warranty = ""; // 보증기간
    this.store = ""; //판매처
    this.note = ""; // 품목상세
    this.requestCid = "";
    this.cmdbAssetInfo = {}; // cmbd 자산정보
    /*
    quantity: amount,
      itemName: categoryName,
      itemCode: categoryId,
      displayCode: displayCode,
      price: String(unitPrice),
      standard: unitType,
      unit: unitValue,
      projectCode: projectCode,
      projectName: projectName,
      */
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    Object.entries(obj).forEach(([key, value]) => {
      if (!_.isObject(value)) {
        if (!Object.prototype.hasOwnProperty.call(this, key)) {
          console.log(`%c model[${key}] empty`, "color:red");
        } else {
          if (typeof value === "number") {
            this[key] = String(value);
          } else {
            // if (key === 'vat' || key === 'savingAmount') {
            //   //계산데이터이기 때문에 바인딩 하면안됨
            //   return;
            // }
            this[key] = value;
          }
        }
      }
    });
    // obj value가 object,array 면 따로 예외처리한다
  }

  setAmount(amount) {
    this.amount = amount;
  }

  setGoodsItem(goodsItem) {
    this.goodsItem = goodsItem;
  }

  convertItemData(obj) {
    console.log("convertItemData :>> ", obj);

    const {
      cateNum,
      category0,
      category1,
      category2,
      displayCode,
      itemCode,
      itemName,
      note,
      price,
      unitPrice,
      regDate,
      standard,
      status,
      statusName,
      warranty,
    } = obj;

    const convertObj = {
      goodsItem: itemName,
      categoryId: itemCode,
      displayCode: displayCode,
      price: unitPrice,
      totalPrice: price,
      goodsNote: note || "",
      warranty: warranty || "",
      standard: standard,
      note: note,
    };

    this.setData(convertObj);
  }

  convertEstimateData(obj) {
    console.log("convertEstimateData :>> ", obj);
    const {
      amount,
      categoryId,
      categoryName,
      displayCode,
      itemNum,
      madeIn,
      note,
      price,
      projectCode,
      projectName,
      requestCid,
      unitPrice,
      unitType,
      unitValue,
    } = obj;
    const convertObj = {
      itemCode: categoryId,
      itemName: categoryName,
      displayCode: displayCode,
      quantity: amount,
      price: String(unitPrice),
      //totalPrice: String(price), 총합은 서버데이터를 넣지 않는다
      projectCode: projectCode,
      projectName: projectName,
      standard: unitType,
      unit: unitValue,
      note: note,
    };
    this.setData(convertObj);
  }

  changeCalcPrice() {
    const { quantity, price } = this;

    if (!quantity && !price) return "0";

    const cal = getMoneyToNumber(quantity) * getMoneyToNumber(price);
    this.totalPrice = String(cal);
  }

  getData() {
    const {
      itemNum,
      itemName,
      itemCode,
      assetsCode, //자산코드
      displayCode,
      quantity,
      price,
      totalPrice,
      marketPrice,
      contractPrice,
      discountRate,
      savingAmount,
      vat,
      savingsRate,
      unitType,
      unitValue,
      store,
      note,
      warranty,
      requestCid,
    } = this;

    const obj = {
      itemNum,
      itemName,
      itemCode,
      assetsCode,
      displayCode,
      discountRate,
      savingsRate,
      unitType,
      unitValue,
      store,
      note,
      warranty,
      requestCid,
      quantity: getMoneyToNumber(quantity),
      price: String(_.floor(getMoneyToNumber(contractPrice) / getMoneyToNumber(this.quantity))),
      totalPrice: getMoneyToNumber(totalPrice),
      marketPrice: getMoneyToNumber(marketPrice),
      contractPrice: getMoneyToNumber(contractPrice),
      vat: getMoneyToNumber(vat),
      savingAmount: getMoneyToNumber(savingAmount),
    };

    return obj;
  }
}

export default SupplyInfoItemModel;
