import { getMoneyToNumber } from "@/utils/stringUtils";
import { dateToString, stringToDate } from "@/utils/dateUtils";

export default class SettleInfoModel {
  constructor() {
    // this.objRaw = null;

    this.contractCalculateList = [];

    this.createItem();
  }

  createItem() {
    const newItem = {
      calculateDate: null,
      calculatePrice: "0",
      draftCid: "",
      partnerInspectYn: true, // 파트너사 검수 필요
      inspectYn: true, // 내부 검수 필요 여부.
      actualExpenseYn: false, // 실비정산 여부
    };

    this.contractCalculateList.push(newItem);

    return newItem;
  }

  setData(objRaw) {
    // this.objRaw = objRaw;

    const { contractCalculateList } = objRaw;

    if (!contractCalculateList) return;

    this.contractCalculateList = contractCalculateList.map((item) => {
      const obj = {
        calculateDate: stringToDate(item.calculateDate),
      };

      const ret = Object.assign({}, item, obj);

      return ret;
    });
  }

  getData() {
    // const obj = Object.assign( {}, this.objRaw );
    let obj = {};

    if (this.contractCalculateList) {
      obj.contractCalculateList = [];

      this.contractCalculateList.forEach((item) => {
        if (!item.calculateDate) return;

        let row = Object.assign({}, item, {
          calculateDate: dateToString(item.calculateDate),
          calculatePrice: getMoneyToNumber(item.calculatePrice),
        });

        if (!row.stateCode) {
          row.stateCode = "INITIAL";
        }

        // row.calculateDate = dateToString( item.calculateDate );

        // row.calculatePrice = getMoneyToNumber( item.calculatePrice );
        // row.draftCid = item.draftCid;
        // row.inspectYn = item.inspectYn;

        // row.stateCode= item.stateCode  || "INITIAL";

        obj.contractCalculateList.push(row);
      });
    }

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}
