import {
  stringToDate,
  dateToString,
  dateToStringWithTime,
  stringToDateWithTime,
} from "@/utils/dateUtils";
import { getMoneyToNumber, getMoneyToKorean } from "@/utils/stringUtils";

class ContractAddInfoModel {
  constructor() {
    // this.objRaw = null;
    //this.finalPriceKr = ''; // 최종계약금액표기
    this.signDate = null; // 계약 체결일
    this.monthPeriod = ""; // 월 정산주기
    this.paymentDay = "1"; // 대급지급기한표기
    this.paymentDayKr = ""; // 대금지급기한 한글표기
    this.vat = "별도"; // 부가세 여부
    this.withholdingTax = "적용안함"; // 원천세
    this.warrantyPeriod = ""; // 무상하자보증기간
    this.warrantyPeriodKr = ""; // 무상하자보증기간 한글표기
  }

  setData(objRaw) {
    // this.objRaw = objRaw;
    const { contractAddInfo } = objRaw;

    //if (finalPrice) this.finalPriceKr = getMoneyToKorean(finalPrice);
    if (contractAddInfo) {
      if (contractAddInfo.signDate) this.signDate = stringToDateWithTime(contractAddInfo.signDate);
      if (contractAddInfo.monthPeriod) this.monthPeriod = contractAddInfo.monthPeriod;
      //if( contractAddInfo.finalPrice )     this.finalPrice = contractAddInfo.finalPrice;
      if (contractAddInfo.paymentDay) this.paymentDay = contractAddInfo.paymentDay;
      if (contractAddInfo.paymentDayKr) this.paymentDayKr = contractAddInfo.paymentDayKr;
      if (contractAddInfo.vat) this.vat = contractAddInfo.vat;
      if (contractAddInfo.withholdingTax) this.withholdingTax = contractAddInfo.withholdingTax;
      if (contractAddInfo.warrantyPeriod) this.warrantyPeriod = contractAddInfo.warrantyPeriod;
      if (contractAddInfo.warrantyPeriodKr)
        this.warrantyPeriodKr = contractAddInfo.warrantyPeriodKr;
    }
  }

  getData() {
    let obj = {};

    if (this.signDate) obj.signDate = dateToStringWithTime(this.signDate);
    //if (this.finalPriceKr) obj.finalPriceKr = this.finalPriceKr;
    if (this.monthPeriod) obj.monthPeriod = this.monthPeriod;
    if (this.paymentDay) obj.paymentDay = this.paymentDay;
    if (this.paymentDay) obj.paymentDayKr = getMoneyToKorean(this.paymentDay);
    if (this.vat) obj.vat = this.vat;
    if (this.withholdingTax) obj.withholdingTax = this.withholdingTax;
    if (this.warrantyPeriod) obj.warrantyPeriod = this.warrantyPeriod;
    if (this.warrantyPeriod) obj.warrantyPeriodKr = getMoneyToKorean(this.warrantyPeriod);

    return obj;
  }

  getAlertMessage() {
    if (!this.signDate) return "계약 체결일을 확인해주세요.";
    if (!this.paymentDay) return "대금지급기한을 확인해주세요.";
    //if( this.vat === 'vatChoice' ) return '부가세를 확인해주세요.';
    if (!this.withholdingTax) return "원천세 포함여부를 확인해주세요.";
    // if( !this.warrantyPeriod ) return '무보증하자보증기간을 확인해주세요.';

    return null;
  }
}

export default ContractAddInfoModel;
