<template>
  <div class="area_view">
    <TableHead title="검수 정보">
      <template v-slot:headRight>
        <div class="group_form">
          <div class="group_form">
            <strong class="txt_view_r">검수 예정일 셋팅</strong>
            <InputNumber class="w48" :value.sync="autoStartMonth" :maxLength="2" />
            <span class="txt_view_r w48">월부터</span>
            <InputNumber class="w48" :value.sync="autoMonthRange" :maxLength="2" />
            <span class="txt_view_r w56">개월간</span>
            <span class="txt_view_l">매달</span>
            <InputNumber class="w48" :value.sync="autoDate" :maxLength="2" />
            <span class="txt_view_r">일로</span>
            <button type="button" class="btn_secondary btn_small" @click="onClickAddAuto">
              정산일 자동 셋팅
            </button>
          </div>
        </div>
      </template>
    </TableHead>
    <Board :disableHover="true" :dataList="model.contractCalculateList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 56px" />
        <!-- NO. -->
        <col style="width: 204px" />
        <!-- 검수 예정일 -->
        <col style="width: 304px" />
        <!-- 금액 -->
        <!-- <col style="width:377px;"> 기안번호 -->
        <!-- <col style="width:164px;"> 검수 필요 -->
        <!-- <col style="width:200px;"> 파트너사 검수 필요 -->
        <col style="width: 292px" />
        <!-- 내부 검수 필요 -->
        <col style="width: 292px" />
        <!-- 실비정산 여부 -->
        <col style="width: 92px" />
        <!-- 삭제 -->
      </template>
      <template v-slot:tr>
        <th>No.</th>
        <th>검수 예정일</th>
        <th>금액</th>
        <!-- <th>기안번호</th> -->
        <!-- <th>검수 필요</th> -->
        <!-- <th>파트너사 검수 필요</th> -->
        <th>내부 검수 필요</th>
        <th>실비정산 여부</th>
        <th>
          <button type="button" class="btn_secondary btn_small" @click="onClickAdd">추가</button>
        </th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in model.contractCalculateList">
          <tr :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <DatePicker :date.sync="item.calculateDate" placeholder="" />
            </td>
            <td>
              <InputAutoMoney :value.sync="item.calculatePrice" />
            </td>
            <!-- <td>
              <Input :value.sync="item.draftCid" />
            </td> -->
            <!-- <td>
              <Radio
                :name="'partnerInspectYn'+index+1"
                :dataList="radioDataList"
                :selectedId.sync="item.partnerInspectYn" />
            </td> -->
            <td>
              <Radio
                :name="'inspectYn' + index + 1"
                :dataList="radioDataList"
                :selectedId.sync="item.inspectYn"
              />
            </td>
            <td>
              <Radio
                :name="'actualExpenseYn' + index + 1"
                :dataList="radioDataList"
                :selectedId.sync="item.actualExpenseYn"
              />
            </td>
            <td>
              <button
                type="button"
                class="btn_fourthly btn_small"
                :disabled="index === 0"
                @click="onClickRemove(item)"
              >
                삭제
              </button>
            </td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="6" class="td_empty">정산 정보가 없습니다.</td>
        </tr>
      </template>
    </Board>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>정산횟수</dt>
          <dd>{{ calculateRowCount }}<span class="util_total">회</span></dd>
          <dt>총금액</dt>
          <dd>
            {{ calculateAmount }}<span class="util_total">{{ currency }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputNumber from "@/components/common/input/InputNumber";
import Radio from "@/components/common/radio/Radio";
import DatePicker from "@/components/common/calendar/DatePicker";

import SettleInfoModel from "./SettleInfoModel";

import { getDate } from "@/utils/dateUtils";
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "SettleInfo",
  components: {
    TableHead,
    Board,
    Input,
    InputAutoMoney,
    InputNumber,
    Radio,
    DatePicker,
  },
  props: {
    model: SettleInfoModel,

    currency: String,
  },
  data() {
    return {
      radioDataList: [
        { id: true, text: "Y" },
        { id: false, text: "N" },
      ],
      autoStartMonth: "",
      autoMonthRange: "",
      autoDate: "",
    };
  },
  computed: {
    calculateRowCount() {
      return this.model.contractCalculateList.length;
    },
    calculateAmount() {
      const result = this.getCalculateAmount();

      return addCommaForMoney(result);
    },
  },
  methods: {
    getCalculateAmount() {
      const result = this.model.contractCalculateList.reduce((accumulator, currentValue) => {
        const calculatePrice = getMoneyToNumber(currentValue.calculatePrice);

        return accumulator + calculatePrice;
      }, 0);

      return result;
    },
    onClickAdd() {
      // if( this.model.contractCalculateList.length >= 10 ) {
      //   return;
      // }

      this.model.createItem();
    },
    onClickRemove(itemToRemove) {
      this.model.contractCalculateList = this.model.contractCalculateList.filter(
        (item) => item !== itemToRemove,
      );
    },
    onClickAddAuto() {
      if (!this.autoStartMonth || !this.autoMonthRange || !this.autoDate) {
        this.$emit("settleInfoError");
        return;
      }
      if (this.autoStartMonth < 1 || this.autoMonthRange < 1 || this.autoDate < 1) {
        const desc = "0보다 큰 값을 입력해주세요.";
        this.$emit("settleInfoError", desc);
        return;
      }

      const autoStartMonth = Number.parseInt(this.autoStartMonth, 10);
      const autoMonthRange = Number.parseInt(this.autoMonthRange);
      const autoDate = Number.parseInt(this.autoDate);

      if (autoStartMonth > 12) {
        this.$emit("settleInfoError");
        return;
      }
      // if(autoMonthRange > 10 ) {
      //   const desc = "최대 10개까지 등록 가능합니다.";
      //   this.$emit('settleInfoError',desc);
      //   return;
      // }
      if (autoDate > 31) {
        this.$emit("settleInfoError");
        return;
      }

      const isHasData = this.model.contractCalculateList.filter(
        (item) => item.calculateDate != null || item.calculatePrice != "0" || item.draftCid != "",
      );
      if (isHasData.length) {
        // const desc = "정산일 자동 셋팅시<br>기존에 등록한 내용이 사라집니다.";
        // this.$emit('settleInfoError',desc);
        // return;
        const params = {
          text: "정산일 자동 셋팅시<br>기존에 등록한 내용이 사라집니다.",
          onClickY: this.onClickAlertOk,
          onClickN: this.onClickAlertCancle,
        };

        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
        return;
      }

      const newList = [];

      let row;

      for (var i = 0; i < autoMonthRange; ++i) {
        row = this.model.createItem();

        row.calculateDate = getDate(autoStartMonth + i, autoDate);

        newList.push(row);
      }

      this.model.contractCalculateList = newList;
    },
    onClickAlertOk() {
      this.model.contractCalculateList = [];
      this.onClickAddAuto();
    },
    onClickAlertCancle() {
      return;
    },
  },
};
</script>
