<template>
  <tr @click="onClickRow">
    <td>{{ endDate }}</td>
    <td>{{ rowData.postCid }}</td>
    <td>{{ rowData.orderCid }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.title">
      {{ rowData.title }}
    </td>
    <td class="td_ellip" :data-ellip="rowData.companyName">
      {{ rowData.companyName }}
    </td>
    <td>{{ priceWithCurrency }}</td>
    <td class="td_ellip" :data-ellip="managerName">
      {{ managerName }}
    </td>
  </tr>
</template>

<script>
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getCountWithCurrency } from "@/utils/spUtils";

import ListLineMixin from "@/mixins/ListLineMixin";

export default {
  name: "ContractAnnouncePopupLine",
  components: {
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  methods: {
    onClickRow() {
      this.$emit("onClickRow", this.rowData);
    },
  },
};
</script>
