<template>
  <TableViewWithTitle title="계약 정보">
    <template v-slot:body>
      <tr>
        <th>계약 상태</th>
        <td>-</td>
        <th>계약 구분</th>
        <td>
          <SelectBox
            class="w208"
            placeholder="선택하세요"
            :dataList="typeList"
            :value.sync="model.contractDiv"
          />
        </td>
      </tr>
      <tr>
        <th>계약 기간<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DateRange :dates.sync="model.dates" />
        </td>
        <th>계약 ID</th>
        <td>{{ model.contractId || "" }}</td>
      </tr>
      <tr>
        <th>계약 체결일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DatePicker :date.sync="addModel.signDate" />
          <!-- :date.sync="model.etcDeliveryDate" -->
        </td>
        <th>계약명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input
            placeholder="계약 정보명을 입력하세요."
            :value.sync="model.description"
            :maxLength="inputMaxLength"
          />
        </td>
      </tr>
      <tr>
        <th>구매 담당자</th>
        <td colspan="3">
          {{ pManagerName }}
        </td>
      </tr>
      <tr>
        <th>계약 요청자</th>
        <td>
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="onClickPopLdap('contractRequester')"
            >
              조직도 ID 검색
            </button>
            <Input
              style="width: 272px"
              placeholder="조직도 아이디를 입력하세요"
              :isDisabled="true"
              :value.sync="contractRequesterIdWithName"
            />
          </div>
        </td>
        <th>요청부서</th>
        <td class="td_text">
          {{ contractDept || "" }}
        </td>
      </tr>
      <tr>
        <th>입찰 공고명</th>
        <td :class="{ tc_b: !model.postTitle }">
          {{ model.postTitle || "입찰공고를 선택할 경우 입력됩니다." }}
        </td>
        <th>발주 번호</th>
        <td :class="{ tc_b: !model.orderCid }">
          {{ model.orderCid || "입찰공고를 선택할 경우 입력됩니다." }}
        </td>
      </tr>
      <!-- <tr>
        <th>분류<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <CategoryGroupIncludeData
            ref="category"
            :category0.sync="model.category0"
            :category1.sync="model.category1"
            :category2.sync="model.category2"
          />
        </td>
      </tr> -->
      <tr>
        <th>절감 정보</th>
        <td colspan="3">
          <SelectBoxReduceStandard placeholder="선택하세요" :value.sync="model.reduceStandard">
            <InputAutoMoney
              class="w188"
              placeholder="절감 기준 금액"
              :maxLength="14"
              :value.sync="model.firstPrice"
              @update:value="onUpdatePrice"
            />
            <span class="bar_view" />
            <strong class="w100 txt_view_c">절감액 (절감율%)</strong>
            <!-- <p class="txt_view_r">{{ model.cutOffPrice || '0 (0.0%)' }}</p> -->
            <p class="txt_view_r">
              {{ cutOffPrice || "0 (0.00%)" }}
            </p>
          </SelectBoxReduceStandard>
        </td>
      </tr>
      <tr>
        <!-- <th>계약 상태<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <SelectBox class="w208"
            :dataList="statusList"
            :value.sync="model.contractStatus" />
        </td> -->
        <th>최종 계약 금액<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <SelectBoxCurrencyIncludeData
            inputWidth="w193"
            isDirectModeInputWidth="w194"
            placeholderCount="최종 계약 금액"
            :inputMaxLength="14"
            :maxCount="model.requestTotalPrice"
            :maxCountErrorText="'구매요청금액보다 최종계약금액이 초과할수 없습니다.'"
            :isAutoMoneyType="true"
            :value.sync="model.currencyCode"
            :count.sync="model.finalPrice"
            @update:count="onUpdatePrice"
          >
            <!-- <InputAutoMoney class="w70" :value.sync="model.finalPrice" /> -->
            <SelectBox class="w130" :dataList="vatDataList" :value.sync="addModel.vat" />
          </SelectBoxCurrencyIncludeData>
        </td>
      </tr>
      <tr>
        <th>원천세<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Radio
            name="withholdingTaxYn"
            :dataList="withholdingTaxDataList"
            :selectedId.sync="addModel.withholdingTax"
          />
        </td>
        <th>대금지급기한<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <div class="group_form">
            <Input class="w90" :maxLength="2" :value.sync="addModel.paymentDay" />
            <span class="txt_view_r">일</span>
          </div>
        </td>
      </tr>
      <tr>
        <th>납품기한</th>
        <td>
          <DatePicker :date.sync="paymentMethodModel.etcDeliveryDate" />
        </td>
        <th>
          무상 하자보증기간
          <span
            v-if="necessaryValue && necessaryValue.warrantyPeriod"
            class="ico_purchase ico_star"
          >
            필수항목
          </span>
        </th>
        <td>
          <div class="group_form">
            <Input class="w90" :maxLength="2" :value.sync="addModel.warrantyPeriod" />
            <span class="txt_view_r">개월</span>
          </div>
        </td>
      </tr>

      <tr>
        <th>비고</th>
        <td colspan="3" class="h96">
          <Textarea :value.sync="model.negoMemo" :maxLength="inputMaxLength" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import DateRange from "@/components/common/calendar/DateRange";
import DatePicker from "@/components/common/calendar/DatePicker";
import Input from "@/components/common/input/Input";
import SelectBox from "@/components/common/selectBox/SelectBox";
import SelectBoxReduceStandard from "./SelectBoxReduceStandard";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import SelectBoxCurrencyIncludeData from "@/components/common/selectBox/SelectBoxCurrencyIncludeData";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Radio from "@/components/common/radio/Radio";
import Textarea from "@/components/common/textarea/Textarea";

import { mapState } from "vuex";
import ContractInfoModel from "./ContractInfoModel";
import ContractAddInfoModel from "./ContractAddInfoModel";
import PaymentMethodModel from "./PaymentMethodModel";

import ApiService from "@/services/ApiService";

import * as _ from "lodash";

import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import { getContractDept } from "@/utils/spUtils";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "ContractInfo",
  components: {
    TableViewWithTitle,
    DateRange,
    DatePicker,
    Input,
    SelectBox,
    CategoryGroupIncludeData,
    SelectBoxCurrencyIncludeData,
    InputAutoMoney,
    Radio,
    Textarea,
    SelectBoxReduceStandard,
  },
  props: {
    model: ContractInfoModel,
    addModel: ContractAddInfoModel,
    paymentMethodModel: PaymentMethodModel,
    contractRequesterIdWithName: String,
    pManagerName: String,
    inputMaxLength: Number,

    necessaryValue: Object,
    // jiraContractInfo:Object,
  },
  data() {
    return {
      cutOffPrice: "",
      vatDataList: [
        // {code:'vatChoice',desc:'부가세(VAT) 선택',name:'부가세(VAT) 선택',value:'vatChoice'},
        { code: "별도", desc: "부가세(VAT) 별도", name: "부가세(VAT) 별도", value: "별도" },
        { code: "포함", desc: "부가세(VAT) 포함", name: "부가세(VAT) 포함", value: "포함" },
        { code: "미포함", desc: "적용안함", name: "적용안함", value: "미포함" },
      ],
      withholdingTaxDataList: [
        { id: "포함", text: "포함" },
        { id: "별도", text: "별도" },
        { id: "적용안함", text: "적용안함" },
      ],
      paymentDateDataList: [
        { code: "1", desc: "1", name: "1", value: 1 },
        { code: "2", desc: "2", name: "2", value: 2 },
        { code: "3", desc: "3", name: "3", value: 3 },
        { code: "4", desc: "4", name: "4", value: 4 },
        { code: "5", desc: "5", name: "5", value: 5 },
        { code: "6", desc: "6", name: "6", value: 6 },
        { code: "7", desc: "7", name: "7", value: 7 },
        { code: "8", desc: "8", name: "8", value: 8 },
        { code: "9", desc: "9", name: "9", value: 9 },
        { code: "10", desc: "10", name: "10", value: 10 },
        { code: "11", desc: "11", name: "11", value: 11 },
        { code: "12", desc: "12", name: "12", value: 12 },
        { code: "13", desc: "13", name: "13", value: 13 },
        { code: "14", desc: "14", name: "14", value: 14 },
        { code: "15", desc: "15", name: "15", value: 15 },
        { code: "16", desc: "16", name: "16", value: 16 },
        { code: "17", desc: "17", name: "17", value: 17 },
        { code: "18", desc: "18", name: "18", value: 18 },
        { code: "19", desc: "19", name: "19", value: 19 },
        { code: "20", desc: "20", name: "20", value: 20 },
        { code: "21", desc: "21", name: "21", value: 21 },
        { code: "22", desc: "22", name: "22", value: 22 },
        { code: "23", desc: "23", name: "23", value: 23 },
        { code: "24", desc: "24", name: "24", value: 24 },
        { code: "25", desc: "25", name: "25", value: 25 },
        { code: "26", desc: "26", name: "26", value: 26 },
        { code: "27", desc: "27", name: "27", value: 27 },
        { code: "28", desc: "28", name: "28", value: 28 },
        { code: "29", desc: "29", name: "29", value: 29 },
        { code: "30", desc: "30", name: "30", value: 30 },
        { code: "31", desc: "31", name: "31", value: 31 },
      ],
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.contract.statusList,
      typeList: (state) => state.contract.typeList,
      partList: (state) => state.contract.partList,
      companyCodeList: (state) => state.contract.companyCodeList,

      currencyList: (state) => state.commonCode.currency,
    }),
    contractDept() {
      const { contractDept } = this.model;

      if (!contractDept) return "";

      return getContractDept(contractDept);
    },
  },
  methods: {
    debouncedQuery: _.debounce(function () {
      this.query();
    }, 300),
    async query() {
      const { firstPrice, finalPrice } = this.model;

      if (!firstPrice || !finalPrice) {
        this.cutOffPrice = "";
        return;
      }

      const nFirstPrice = getMoneyToNumber(firstPrice);
      const nFinalPrice = getMoneyToNumber(finalPrice);

      const path = `${this.$apiPath.CONTRACT_PRICE}/${nFirstPrice}/${nFinalPrice}`;
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        return;
      }
      const orginData = result.data.cutOffPrice;
      const price = orginData.split(" (")[0];
      const percent = " (" + orginData.split(" (")[1];
      this.cutOffPrice = addCommaForMoney(price) + percent;
    },
    onUpdatePrice(value) {
      this.debouncedQuery();
    },

    onClickPopLdap(target) {
      this.$emit("onClickPopLdap", target);
    },
    onClickPopAnnounceSelect() {
      this.$emit("onClickPopAnnounceSelect");
    },
  },
};
</script>
