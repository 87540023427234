import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

export default class AddInfoModel {
  constructor() {
    this.objRaw = {};

    this.delivery = ""; //설치수행장소
    this.why = ""; //왜??

    this.specialAgreement = ""; //특이사항
  }

  setData(obj) {
    this.objRaw = _.cloneDeep(obj);
    const { delivery, why, specialAgreement } = obj;
    Object.entries({ delivery, why, specialAgreement }).forEach(([key, value]) => {
      if (!_.isObject(value)) {
        if (!Object.prototype.hasOwnProperty.call(this, key)) {
          console.error(`model[${key}] empty`);
        }
        this[key] = value || "";
      }
    });
    // obj value가 object,array 면 따로 예외처리한다
  }

  getData() {
    const { delivery, why, specialAgreement } = this;
    const obj = {
      delivery,
      why,
      specialAgreement,
    };

    return obj;
  }
}
