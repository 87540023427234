<template>
  <TableViewWithTitle title="담당자 정보">
    <template v-slot:header>
      <tr>
        <th colspan="2">구매 담당자</th>
        <th colspan="2">구매 요청자</th>
      </tr>
    </template>

    <template v-slot:body>
      <tr>
        <th>구매 담당자</th>
        <td>
          {{ `${managerInfoModel.managerName} (${managerInfoModel.managerId})` }}
        </td>
        <th>구매 요청자<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          {{ `${managerInfoModel.bManager.name} (${managerInfoModel.bManager.loginId})` }}
          <!-- <Input
              style="width: 272px"
              placeholder="-"
              :isDisabled="true"
              :value.sync="computedContractRequester"
            /> -->
        </td>
      </tr>
      <tr>
        <th>전화번호 (내선)</th>
        <td>
          {{ `${managerInfoModel.managerTel} ` | nullToDash }}
        </td>
        <th>전화번호 (내선)</th>
        <td>
          {{ `${managerInfoModel.bManager.tel}` | nullToDash }}
          <!-- <InputPhone
            :useMobile="true"
            :useArea="true"
            :phone0.sync="manager1.tel.phone0"
            :phone1.sync="manager1.tel.phone1"
            :phone2.sync="manager1.tel.phone2"
          /> -->
        </td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>
          {{ `${managerInfoModel.managerEmail}` | nullToDash }}
        </td>
        <th>이메일</th>
        <td>
          {{ `${managerInfoModel.bManager.email}` | nullToDash }}
          <!-- <InputEmail
            :emailName.sync="manager1.email.name"
            :emailDomain.sync="manager1.email.domain"
          /> -->
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    managerInfoModel: Object,
  },
  computed: {},
  methods: {},
};
</script>
