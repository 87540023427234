<template>
  <Fold title="특약사항" :isFolded="true">
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col />
      </template>
      <template v-slot:body>
        <tr>
          <th>특약사항</th>
          <td class="h96">
            <Textarea :value.sync="model.specialAgreement" />
          </td>
        </tr>
      </template>
    </TableView>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import Textarea from "@/components/common/textarea/Textarea";
import PaymentMethod from "./PaymentMethodModel";
// import OrderInfoModel from './OrderInfoModel'

export default {
  name: "SpecialAgreement",
  components: {
    Fold,
    TableView,
    Textarea,
  },
  props: {
    specialAgreement: String,
    model: PaymentMethod,
  },
  computed: {
    specialAgreementSync: {
      get() {
        return this.PaymentMethod.specialAgreement;
      },
      set(value) {
        this.$emit("update:PaymentMethod.specialAgreement", value);
      },
    },
  },
};
</script>
