<template>
  <Fold title="정산 정보" :isFolded="true">
    <Board :disableHover="true" :dataList="model.contractItemList" :showPagination="false">
      <template v-slot:colgroup>
        <!-- NO. -->
        <col style="width: 55px" />

        <!-- 단가 -->
        <col style="" />
        <!-- 삭제 -->
        <col style="width: 91px" />
      </template>
      <template v-slot:tr>
        <th>No.</th>

        <th>단가</th>
        <th>
          <button type="button" class="btn_secondary btn_small" @click="onClickAdd">추가</button>
        </th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in model.contractItemList">
          <tr :key="index">
            <td>{{ index + 1 }}</td>

            <td>
              <InputAutoMoney :value.sync="item.unitPrice" />
            </td>
            <td>
              <button
                type="button"
                class="btn_fourthly btn_small"
                :disabled="index === 0"
                @click="onClickRemove(item)"
              >
                삭제
              </button>
            </td>
          </tr>
        </template>
      </template>
    </Board>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import SettleUnitCostModel from "./SettleUnitCostModel";

export default {
  name: "SettleUnitCost",
  components: {
    Fold,
    Board,
    Input,
    InputAutoMoney,
  },
  props: {
    model: SettleUnitCostModel,
  },
  methods: {
    onClickAdd() {
      // 개수 제한 푼다.
      // if( this.model.contractItemList.length >= 10 ) {
      //   return;
      // }

      this.model.createItem();
    },
    onClickRemove(itemToRemove) {
      this.model.contractItemList = this.model.contractItemList.filter(
        (item) => item !== itemToRemove,
      );
    },
  },
};
</script>
