<template>
  <FilterBarBase
    btnSize="medium"
    :templateAddBtn="false"
    @onClickSearch="onClickSearch"
    @onClickTemplateAdd="onClickTemplateAdd"
  >
    <dl>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <!-- 임시 주석처리
           <SelectBox
            class="w152"
            :dataList="typeData01.dataList"
            :value.sync="typeData01.selectedId" /> -->
          <SelectBox
            class="w152"
            :dataList="typeData02"
            :value.sync="stateId"
            :isDisabled="isSelectBoxDisabled"
          />
          <Input
            class="w300"
            :value.sync="inputCurrentText"
            :isFocusOnMount="true"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Input from "@/components/common/input/Input";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "TemplateSelectPopupFilterBar",
  components: {
    FilterBarBase,
    SelectBox,
    Input,
  },
  mixins: [FilterBarMixin],
  props: {
    //typeData01:Object,
    typeData02: Array,
    defaultStateId: String,
    isSelectBoxDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputData: {
        placeholder: null,
      },
      inputCurrentText: "",
      typeData: [],
      status: this.defaultStateId,
    };
  },
  watch: {
    defaultStateId(val) {
      this.stateId = this.defaultStateId;
    },
  },
  methods: {
    onClickSearch() {
      // if( !this.inputCurrentText.trim() ) {
      //   this.alert( '내용을 입력해주세요.' );
      //   return;
      // }

      //const obj = getCommonFilterbarData();

      const ret = {
        keywordInputText: this.inputCurrentText,
        templateGubun: this.stateId,
        //templateGubun2 : this.typeData01.selectedId
      };

      this.$emit("onClickSearch", ret);
    },
    onClickTemplateAdd() {
      this.$emit("onClickTemplateAdd");
    },
    // getCommonFilterbarData(){

    //   const ret = {
    //     text: this.inputCurrentText,
    //     templateGubun : this.typeData02.selectedId
    //   }

    //   return ret;
    // }
    // alert( text ) {
    //   this.$emit( 'alert', text );
    // },
  },
};
</script>
