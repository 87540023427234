<template>
  <Fold title="계약 조건" :isFolded="true">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>계약 형태</th>
          <td>
            <SelectBox
              class="w208"
              :dataList="conditionTypeListExceptAll"
              :isDisabled="isDisabledConditionType"
              :value.sync="model.contractType"
            />
          </td>
          <th>계약서 자동연장</th>
          <td>
            <Radio name="radio0" :dataList="radioDataList" :selectedId.sync="model.extendYn" />
          </td>
        </tr>
        <tr>
          <th>하도급 여부</th>
          <td>
            <Radio name="radio1" :dataList="radioDataList" :selectedId.sync="model.subContractYn" />
          </td>
          <th>경쟁비딩 여부</th>
          <td>
            <Radio name="radio2" :dataList="radioDataList" :selectedId.sync="model.bidYn" />
          </td>
        </tr>
        <tr>
          <th>정산 여부</th>
          <td>
            <div class="group_form">
              <Radio
                name="radio3"
                :dataList="calculateRadioList"
                :selectedId.sync="model.calculateYn"
              />
              <span v-if="performanceUseYn == 'Y' && !isInfra" class="txt_view_r tc_red"
                >* 정산을 하지 않을 경우 수행도평가를 해제해주세요.</span
              >
            </div>
            <!--<Radio
              name="radio3"
              :dataList="radioDataList"
              :selectedId.sync="calculateYn" />-->
          </td>
          <th>계약이행보증보험</th>
          <td>
            <Radio
              name="radio4"
              :dataList="radioDataList"
              :selectedId.sync="model.insuranceYn"
              @onChange="onChange"
            />
            <!-- 계약이행보증보험 데이터 값으로 변경 필요! -->
          </td>
        </tr>
        <tr>
          <th>비딩참여 업체</th>
          <td colspan="3">
            <Input class="w380" :value.sync="model.bidCompany" />
          </td>
        </tr>
      </template>
    </TableView>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import Input from "@/components/common/input/Input";
import SelectBox from "@/components/common/selectBox/SelectBox";
import Radio from "@/components/common/radio/Radio";
import PageMixin from "@/mixins/PageMixin";

import ContractConditionModel from "./ContractConditionModel";

import { mapState } from "vuex";

export default {
  name: "ContractCondition",
  components: {
    Fold,
    TableView,
    Input,
    SelectBox,
    Radio,
  },
  mixins: [PageMixin],
  props: {
    model: ContractConditionModel,
    performanceUseYn: String,
    isInfra: Boolean,
    // isDisabled: Boolean,
    templateName: String,
  },
  data() {
    return {
      radioDataList: [
        { id: true, text: "Y" },
        { id: false, text: "N" },
      ],
      // calculateRadioList: [
      //   { id: true, text: 'Y' },
      //   { id: false, text: 'N', isDisabled:true },
      // ],
      insuranceYn: this.model.insuranceYn,
      //isDisabledOrNot: this.performanceUseYn == "Y" ? false : true,
    };
  },
  mounted() {
    this.calculateRadioList[1].isDisabled =
      this.performanceUseYn == "Y" && !this.isInfra ? true : false;
  },
  computed: {
    ...mapState({
      conditionTypeList: (state) => state.contract.conditionTypeList,
    }),
    isDisabledConditionType() {
      const hasNoData = !this.conditionTypeList || this.conditionTypeList.length === 0;
      return hasNoData;
    },
    conditionTypeListExceptAll() {
      return this.conditionTypeList.filter((item) => item.code !== "0");
    },
    isDisabledCalculate() {
      // performanceUseYn == "Y"면 N Disabled
    },
    calculateRadioList() {
      var disabled = false;
      // 수행도평가 = "Y" -> 정산여부 "Y" & 수행도평가 = "N" => 정산여부 상관없음.
      if (this.performanceUseYn == "Y" && !this.isInfra) {
        disabled = true;
        this.model.calculateYn = true;
      }
      let array = [
        { id: true, text: "Y" },
        { id: false, text: "N", isDisabled: disabled },
      ];
      return array;
    },
  },
  methods: {
    onChange(to, from) {
      if (to == "true" && from == false) {
        if (this.templateName.indexOf("계약보증") == -1) {
          this.$emit(
            "alertSelectTemplate",
            "계약이행보증보험 보증서를 포함한 탬플릿을 선택해주세요. ",
          );
        }
      }
    },
  },
};
</script>
